/* eslint-disable default-case */
/* eslint-disable react/no-array-index-key */
import { IonButton, IonRow, IonCol } from '@ionic/react'
import React, { FC, useEffect, useState } from 'react'
import moment from 'moment'
import { FocusedActionKind } from '../../../hooks/terrain-mapping/helpers/StateReducers'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { useAuth } from '../../../hooks/useAuth'
import { ObscureBackground, OverlayContainer } from '../../GlobalContainers'
import { SimpleButton } from '../../maps/StyledContainers'
import {
  EngagementNote, Flashpoint, Stakeholder, TerrainIssue,
} from '../../types/OptimizedMaps'
import FlashpointIcon from '../icons/flashpoint.svg'
import StakeholderIcon from '../icons/stakeholder.svg'
import IssueIcon from '../icons/issue.svg'
import EngagementIcon from '../icons/eng-note.svg'
import { BlueButton } from '../../incident-management/StyledContainers'
import { TabOption } from '../../projects/StyledContainers'
import StakeholderMap from './CommunityStakeholderMap'
import CommitmentList from '../listviews/CommitmentList'
import EngagementList from '../listviews/EngagementList'
import FlashpointList from '../listviews/FlashpointList'
import IssuesList from '../listviews/IssueList'
import POIList from '../listviews/POIList'
import StakeholderList from '../listviews/StakeholderList'
import { ListContainer } from '../../consultant/StyledContainers'
import { getColor } from '../listviews/FlashpointList'
import axios from 'axios'

const CommunityProfile : FC = () => {
  const {
    focusedElems, dispatchFocused, engagementNotes, users, setLoading,
    stakeholders, flashpoints, issues, setEditing, communities, editing,
    setShowStakeholderForm, setShowEngagementForm, setShowIssueForm,
    setShowFlashpointForm, setFromCommunityAdd, assets, regions
  } = useTerrainMapping()
  const { focusedCommunity } = focusedElems
  const { workspace } = useWorkspace()
  const { user } = useAuth()

  const [factors, setFactors] = useState<(Flashpoint | Stakeholder | EngagementNote | TerrainIssue)[]>([])
  const [view, setView] = useState<String>('Home')
  const [focusedStakeholders, setFocusedStakeholders] = useState<Stakeholder[]>([])
  const [focusedFlashpoints, setFocusedFlashpoints] = useState<Flashpoint[]>([])
  const [focusedIssues, setFocusedIssues] = useState<TerrainIssue[]>([])
  const [focusedNotes, setFocusedNotes] = useState<EngagementNote[]>([])
  const [focusedComm, setFocusedComm] = useState<any>(focusedCommunity)
  const [content, setContent] = useState<string>()
  const [subject, setSubject] = useState<string>()
  const [selectedNote, setSelectedNote] = useState<any>()

  useEffect(() => {
    const filteredEngs = engagementNotes.filter(({ community }) => community && community.id === focusedComm.id)
    const filteredStakeholders = stakeholders.filter(({ areas }) => areas.find(({ id }) => id === focusedComm.id))
    const filteredFlashpoints = flashpoints.filter(({ relevant_areas }) => relevant_areas.find(({ id }) => id === focusedComm.id))
    const filteredIssues = issues.filter(({ communities }) => communities.find(({ id }) => id === focusedComm.id))

    setFocusedStakeholders(filteredStakeholders)
    setFocusedFlashpoints(filteredFlashpoints)
    setFocusedIssues(filteredIssues)
    setFocusedNotes(filteredEngs)

    setFactors([...filteredEngs, ...filteredStakeholders, ...filteredFlashpoints, ...filteredIssues])
  }, [])

  console.log(focusedComm)

  console.log(regions)

  const handleAddSelector = () => {
    setFromCommunityAdd(focusedComm)
    if (view === 'Stakeholders') {
      setShowStakeholderForm(true)
      dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })
    } else if (view === 'Flashpoints') {
      setShowFlashpointForm(true)
      dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })
    } else if (view === 'Engagement Notes') {
      setShowEngagementForm(true)
      dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })
    } else if (view === 'Issues') {
      setShowIssueForm(true)
      dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })
    }
  }

  const getNoteUser = (user_id) => {
    const user = users.find((e) => e.id === user_id)
    if (user) {
      return { value: user.id, label: `${user.first_name} ${user.final_name}` }
    }
    return { value: null, label: 'N/A' }
  }

  const handleEditNote = () => {
    setLoading(true)

    const updatedNote = {
      id: selectedNote.id,
      subject,
      content,
    }

    axios.put('/api/v1/asset/edit_note', {
      domain_id: workspace.id,
      subject,
      note_id: selectedNote.id,
      content,
    }).then(() => {
      setEditing(false)
      setLoading(false)
      setView('Notes')
      // setSelectedAsset((prevState) => {
      //   const updatedNotes = prevState.notes.map((note) => (note.id === updatedNote.id ? updatedNote : note))
      //   return {
      //     ...prevState,
      //     notes: updatedNotes,
      //   }
      // })
    })
  }

  const handleAddNote = () => {
    setLoading(true)
    console.log('focused comm note: ', focusedComm)
    axios.post('/api/v1/asset/add_note', {
      domain_id: workspace.id,
      subject,
      community_id: focusedComm.id,
      content,
    }).then((response) => {
      // Assuming the response contains the newly created note's ID
      const { id } = response.data // Extract the ID from the response
      // Create a new note object with the ID and other details
      const newNote = {
        id, // Use the ID from the response
        subject,
        content,
        created_at: new Date().toISOString(), // Or any other fields you want to set
        updated_at: new Date().toISOString(), // Same as above
        user_id: user.user_id,
      }
      // Update the selectedAsset.notes with the new note
      // setFocusedComm((prevState: any) => ({ ...prevState, notes: [...prevState.notes, newNote] }))
      setEditing(false)
      setLoading(false)
      setView('Notes')
    }).catch((error) => {
      console.error('Error adding note:', error)
      setLoading(false)
    })
  }

  const handleSubjectChange = (event) => {
    setSubject(event.target.value)
  }

  const handleContentChange = (event) => {
    setContent(event.target.value)
  }

  console.log('selected comm', focusedComm)

  const countyRegionMap = new Map();
  regions.forEach(region => {
    region.counties.forEach(county => {
      countyRegionMap.set(county.id, { countyName: county.name, regionName: region.name })
    })
  })

  switch (view) {
    case 'Governance':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Home')
                  }}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#8E151F', fontWeight: 'bold',
                  }}
                  onClick={() => {
                    setView('Governance')
                  }}
                >Governance
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Notes')
                  }}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Flashpoints')
                  }}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('StakeholderMap')
                  }}
                >Stakeholder Map
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Stakeholders')
                  }}
                >Stakeholders
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Issues')
                  }}
                >Issues
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Engagement Notes')
                  }}
                >Engagement Notes
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })}>Close X</SimpleButton>
            </IonRow>
            <IonRow className='ion-justify-content-between ion-align-items-center'>
              <h5 style={{ marginLeft: '2em', color: '#28464B', fontSize: 16 }}>{ focusedComm.name }</h5>
            </IonRow>
            <div style={{ height: '78%', overflow: 'auto' }}>
              <div style={{ padding: '20px 0' }}>
                <IonRow>
                  <IonCol>
                    <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Community Head</h5>
                    <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>focusedComm.head_id</p>
                  </IonCol>
                </IonRow>
              </div>
              {
                focusedComm.governors?.length > 0 && (
                  <div style={{ padding: '20px 0', marginLeft: '4em' }}>
                    <h5 style={{ color: '#326771' }}>Governing Body</h5>
                    <ul>
                      {focusedComm.governors?.map((gov) => (
                        <li key={gov.governor_id} style={{ color: 'black' }}>
                          { (stakeholders.find((stakeholder) => stakeholder.id === gov.governor_id) || {}).name || 'N/A' }
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              }
              <div style={{ padding: '20px 0' }}>
                <IonRow>
                  <IonCol>
                    <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Local Dispute Resolution</h5>
                    <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>focusedComm.ldr</p>
                  </IonCol>
                </IonRow>
              </div>
            </div>
            <IonRow className='ion-justify-content-end'>
              <IonButton onClick={() => setEditing(true)} style={{ '--background': '#326771' }}>Edit</IonButton>
            </IonRow>
          </OverlayContainer>
        </>
      )
    case 'Home':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#8E151F', fontWeight: 'bold',
                  }}
                  onClick={() => {
                    setView('Home')
                  }}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Governance')
                  }}
                >Governance
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Notes')
                  }}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Flashpoints')
                  }}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('StakeholderMap')
                  }}
                >Stakeholder Map
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Stakeholders')
                  }}
                >Stakeholders
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Issues')
                  }}
                >Issues
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Engagement Notes')
                  }}
                >Engagement Notes
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })}>Close X</SimpleButton>
            </IonRow>
            <IonRow className='ion-justify-content-between ion-align-items-center'>
              <h5 style={{ marginLeft: '2em', color: '#28464B', fontSize: 16 }}>{ focusedComm.name }</h5>
            </IonRow>
            <div style={{ height: '78%', overflow: 'auto' }}>
              <div style={{ padding: '20px 0', marginLeft: '4em' }}>
                <IonRow>
                  <IonCol>
                    <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Asset Type</h5>
                    <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{ focusedComm.community_type }</p>
                  </IonCol>
                  <IonCol>
                    <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Region/State</h5>
                    <ul style={{ margin: 0, padding: 0, listStyleType: 'disc', paddingLeft: '20px' }}>
                      {focusedComm.counties.map((comm, index) => {
                        const countyInfo = countyRegionMap.get(comm.county_id)
                        return (
                          <li key={index} style={{ fontSize: 13, color: '#737475' }}>
                            {countyInfo
                              ? `${countyInfo.regionName}`
                              : 'Unknown Region'}
                          </li>
                        )
                      })}
                    </ul>
                  </IonCol>
                  <IonCol>
                    <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>County</h5>
                    <ul style={{ margin: 0, padding: 0, listStyleType: 'disc', paddingLeft: '20px' }}>
                      {focusedComm.counties.map((comm, index) => {
                        const countyInfo = countyRegionMap.get(comm.county_id)
                        return (
                          <li key={index} style={{ fontSize: 13, color: '#737475' }}>
                            {countyInfo
                              ? `${countyInfo.countyName}`
                              : 'Unknown County'}
                          </li>
                        )
                      })}
                    </ul>
                  </IonCol>
                </IonRow>
              </div>
              <div style={{ padding: '20px 0', marginLeft: '4em' }}>
                <IonRow>
                  <IonCol>
                    <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Parent Community</h5>
                    <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{`${(communities.find((e) => e.id === focusedComm.parent_id))?.name || 'N/A'}`}</p>
                  </IonCol>
                  <IonCol>
                    <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Sub Communities</h5>
                    <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>sub comms list</p>
                  </IonCol>
                  <IonCol>
                    <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Associated Assets</h5>
                    <ul style={{ margin: 0, padding: 0, listStyleType: 'disc', paddingLeft: '20px' }}>
                      {focusedComm.assets.map((assoc, index) => {
                        const asset = assets.find((asset) => asset.id === assoc.asset_id)
                        return (
                          <li key={index} style={{ fontSize: 13, color: '#737475' }}>
                            {asset ? `${asset.name}, ${asset.asset_type}` : 'N/A'}
                          </li>
                        )
                      })}
                    </ul>
                  </IonCol>
                </IonRow>
              </div>
              <div style={{ padding: '20px 0', marginLeft: '4em' }}>
                <IonRow>
                  <IonCol>
                    <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Description</h5>
                    <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{ focusedComm.description }</p>
                  </IonCol>
                </IonRow>
              </div>

            </div>
            <IonRow className='ion-justify-content-end'>
              <IonButton onClick={() => setEditing(true)} style={{ '--background': '#326771' }}>Edit</IonButton>
            </IonRow>
          </OverlayContainer>
        </>
      )
    case 'StakeholderMap':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '100%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Home')
                  }}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Governance')
                  }}
                >Governance
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Notes')
                  }}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Flashpoints')
                  }}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', fontWeight: 'bold', color: '#8E151F',
                  }}
                  onClick={() => {
                    setView('StakeholderMap')
                  }}
                >Stakeholder Map
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Stakeholders')
                  }}
                >Stakeholders
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Issues')
                  }}
                >Issues
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Engagement Notes')
                  }}
                >Engagement Notes
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })}>Close X</SimpleButton>
            </IonRow>
            <StakeholderMap
              community={focusedComm}
              stakeholders={focusedComm.stakeholder_areas}
              setFocusedCommunity={setFocusedComm}
            />
          </OverlayContainer>
        </>
      )
    case 'Flashpoints':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Home')
                  }}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Governance')
                  }}
                >Governance
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Notes')
                  }}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', fontWeight: 'bold', color: '#8E151F',
                  }}
                  onClick={() => {
                    setView('Flashpoints')
                  }}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('StakeholderMap')
                  }}
                >Stakeholder Map
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Stakeholders')
                  }}
                >Stakeholders
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Issues')
                  }}
                >Issues
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Engagement Notes')
                  }}
                >Engagement Notes
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })}>Close X</SimpleButton>
            </IonRow>
            <>
              <div style={{ height: '75%', overflow: 'auto', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <IonRow>
                  <h5 style={{ marginTop: '2em' }}>Flashpoints</h5>
                </IonRow>
                <IonRow style={{ padding: '10px' }} className='terrain-list-title ion-justify-content-between ion-align-items-center'>
                  <IonCol size='2'>
                    <h5 style={{ color: '#326771', fontSize: 16 }}>Title</h5>
                  </IonCol>
                  <IonCol size='4'>
                    <h5 style={{ color: '#326771', fontSize: 16 }}>Description</h5>
                  </IonCol>
                  <IonCol size='2'>
                    <h5 style={{ color: '#326771', fontSize: 16 }}>Status</h5>
                  </IonCol>
                  <IonCol size='2'>
                    <h5 style={{ color: '#326771', fontSize: 16 }}>Manifestation</h5>
                  </IonCol>
                  <IonCol size='2' />
                </IonRow>
                <div style={{ flex: 1, overflow: 'auto' }}>
                  {
                    focusedFlashpoints.map((val, index) => (
                      <IonRow
                        className='ion-justify-content-between terrain-register-row ion-align-items-center'
                        style={{ backgroundColor: getColor(index) }}
                        key={val.id}
                      >
                        <IonCol size='2'>{ val.title }</IonCol>
                        <IonCol size='4'>{ val.description }</IonCol>
                        <IonCol size='2'>{ val.status }</IonCol>
                        <IonCol size='2'>{ val.primaryManifestation }</IonCol>
                        <IonCol size='2'>
                          <BlueButton onClick={() => dispatchFocused({ type: FocusedActionKind.SELECT_FLASHPOINT, flashpoint: val })}>View</BlueButton>
                        </IonCol>
                      </IonRow>
                    ))
                  }
                  {
                    focusedFlashpoints.length === 0 && (
                      <p style={{ textAlign: 'center' }}>No flashpoints created so far</p>
                    )
                  }
                </div>
              </div>
            </>
            <IonRow className='ion-justify-content-end'>
              <IonButton onClick={() => handleAddSelector()} style={{ '--background': '#0C9500', marginTop: '1.4em' }}>Add Flashpoint</IonButton>
            </IonRow>
          </OverlayContainer>
        </>
      )
    case 'Stakeholders':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Home')
                  }}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Governance')
                  }}
                >Governance
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Notes')
                  }}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Flashpoints')
                  }}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('StakeholderMap')
                  }}
                >Stakeholder Map
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', fontWeight: 'bold', color: '#8E151F',
                  }}
                  onClick={() => {
                    setView('Stakeholders')
                  }}
                >Stakeholders
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Issues')
                  }}
                >Issues
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Engagement Notes')
                  }}
                >Engagement Notes
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })}>Close X</SimpleButton>
            </IonRow>
            <>
              <div style={{ height: '75%', overflow: 'auto', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>              
                <IonRow>
                  <h5 style={{ marginTop: '2em' }}>Stakeholders</h5>
                </IonRow>
                <IonRow style={{ padding: '10px' }} className='terrain-list-title ion-justify-content-between ion-align-items-center'>
                  <IonCol size='2'>
                    <h5 style={{ color: '#326771', fontSize: 16 }}>Name</h5>
                  </IonCol>
                  <IonCol size='2'>
                    <h5 style={{ color: '#326771', fontSize: 16 }}>Type</h5>
                  </IonCol>
                  <IonCol size='2'>
                    <h5 style={{ color: '#326771', fontSize: 16 }}>Role/Position</h5>
                  </IonCol>
                  <IonCol size='2'>
                    <h5 style={{ color: '#326771', fontSize: 16 }}>Associated Organisations</h5>
                  </IonCol>
                  <IonCol size='2'>
                    <h5 style={{ color: '#326771', fontSize: 16 }}>Associated Locations</h5>
                  </IonCol>
                  <IonCol size='2' />
                </IonRow>
                <div style={{ flex: 1, overflow: 'auto' }}>
                  {
                    focusedStakeholders.map((val, index) => (
                      <IonRow
                        className='ion-justify-content-between terrain-register-row ion-align-items-center'
                        style={{ backgroundColor: getColor(index) }}
                        key={val.id}
                      >
                        <IonCol size='2'>{ val.name }</IonCol>
                        <IonCol size='2'>{ val.type }</IonCol>
                        <IonCol size='2'>{ val.role }</IonCol>
                        <IonCol size='2'>{ (val.organisations.length > 0) ? val.organisations.map(({ name }) => name).join(', ') : 'No organisations' }</IonCol>
                        <IonCol size='2'>{ (val.areas.length > 0) ? val.areas.map(({ name }) => name).join(', ') : 'No areas' }</IonCol>
                        <IonCol size='2'>
                          <BlueButton onClick={() => setFocusedStakeholder(val)}>View</BlueButton>
                        </IonCol>
                      </IonRow>
                    ))
                  }
                  {
                    focusedStakeholders.length === 0 && (
                      <p style={{ textAlign: 'center' }}>No stakeholders available</p>
                    )
                  }
                </div>
              </div>
            </>
            <IonRow className='ion-justify-content-end'>
              <IonButton onClick={() => handleAddSelector()} style={{ '--background': '#0C9500', marginTop: '1.4em' }}>Add Stakeholder</IonButton>
            </IonRow>
          </OverlayContainer>
        </>
      )
    case 'Issues':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Home')
                  }}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Governance')
                  }}
                >Governance
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Notes')
                  }}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Flashpoints')
                  }}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('StakeholderMap')
                  }}
                >Stakeholder Map
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Stakeholders')
                  }}
                >Stakeholders
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', fontWeight: 'bold', color: '#8E151F',
                  }}
                  onClick={() => {
                    setView('Issues')
                  }}
                >Issues
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Engagement Notes')
                  }}
                >Engagement Notes
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })}>Close X</SimpleButton>
            </IonRow>
            <>
              <div style={{ height: '75%', overflow: 'auto', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <IonRow>
                  <h5 style={{ marginTop: '2em' }}>Issues</h5>
                </IonRow>
                <IonRow style={{ padding: '10px' }} className='terrain-list-title ion-justify-content-between ion-align-items-center'>
                  <IonCol size='2'>
                    <h5 style={{ color: '#326771', fontSize: 16 }}>Title</h5>
                  </IonCol>
                  <IonCol size='2'>
                    <h5 style={{ color: '#326771', fontSize: 16 }}>Description</h5>
                  </IonCol>
                  <IonCol size='2'>
                    <h5 style={{ color: '#326771', fontSize: 16 }}>Type</h5>
                  </IonCol>
                  <IonCol size='2'>
                    <h5 style={{ color: '#326771', fontSize: 16 }}>Associated Groups</h5>
                  </IonCol>
                  <IonCol size='2'>
                    <h5 style={{ color: '#326771', fontSize: 16 }}>Associated Areas</h5>
                  </IonCol>
                  <IonCol size='2' />
                </IonRow>
                <div style={{ flex: 1, overflow: 'auto' }}>
                  {
                    focusedIssues.map((val, index) => (
                      <IonRow
                        className='ion-justify-content-between terrain-register-row ion-align-items-center'
                        style={{ backgroundColor: getColor(index) }}
                        key={val.id}
                      >
                        <IonCol size='2'>{ val.title }</IonCol>
                        <IonCol size='2'>{ val.description }</IonCol>
                        <IonCol size='2'>{ val.type }</IonCol>
                        <IonCol size='2'>{ (val.stakeholders.length === 0) ? 'No Groups selected' : val.stakeholders.map(({ name }) => name).join(', ') }</IonCol>
                        <IonCol size='2'>{ (val.communities.length === 0) ? 'No Areas selected' : val.communities.map(({ name }) => name).join(', ') }</IonCol>
                        <IonCol size='2'>
                          <BlueButton onClick={() => dispatchFocused({ type: FocusedActionKind.SELECT_ISSUE, issue: val })}>View</BlueButton>
                        </IonCol>
                      </IonRow>
                    ))
                  }
                  {
                    focusedIssues.length === 0 && (
                      <p style={{ textAlign: 'center' }}>No issues available</p>
                    )
                  }
                </div>
              </div>
            </>
            <IonRow className='ion-justify-content-end'>
              <IonButton onClick={() => handleAddSelector()} style={{ '--background': '#0C9500', marginTop: '1.4em' }}>Add Issue</IonButton>
            </IonRow>
          </OverlayContainer>
        </>
      )
    case 'Engagement Notes':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Home')
                  }}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Governance')
                  }}
                >Governance
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Notes')
                  }}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Flashpoints')
                  }}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('StakeholderMap')
                  }}
                >Stakeholder Map
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Stakeholders')
                  }}
                >Stakeholders
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Issues')
                  }}
                >Issues
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', fontWeight: 'bold', color: '#8E151F',
                  }}
                  onClick={() => {
                    setView('Engagement Notes')
                  }}
                >Engagement Notes
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })}>Close X</SimpleButton>
            </IonRow>
            <>
              <div style={{ height: '75%', overflow: 'auto', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>              
                <IonRow>
                  <h5 style={{ marginTop: '2em' }}>Engagement Notes</h5>
                </IonRow>
                <IonRow style={{ padding: '10px' }} className='terrain-list-title ion-justify-content-between ion-align-items-center'>
                  <IonCol size='2'>
                    <h5 style={{ color: '#326771', fontSize: 16 }}>Subject</h5>
                  </IonCol>
                  <IonCol size='2'>
                    <h5 style={{ color: '#326771', fontSize: 16 }}>Type</h5>
                  </IonCol>
                  <IonCol size='2'>
                    <h5 style={{ color: '#326771', fontSize: 16 }}>Aim</h5>
                  </IonCol>
                  <IonCol size='2'>
                    <h5 style={{ color: '#326771', fontSize: 16 }}>Associated Community</h5>
                  </IonCol>
                  <IonCol size='2'>
                    <h5 style={{ color: '#326771', fontSize: 16 }}>Date</h5>
                  </IonCol>
                  <IonCol size='2' />
                </IonRow>
                <div style={{ flex: 1, overflow: 'auto' }}>
                  {
                    focusedNotes.map((val, index) => (
                      <IonRow
                        className='ion-justify-content-between terrain-register-row ion-align-items-center'
                        style={{ backgroundColor: getColor(index) }}
                        key={val.id}
                      >
                        <IonCol size='2'>{ val.subject }</IonCol>
                        <IonCol size='2'>{ val.type }</IonCol>
                        <IonCol size='2'>{ val.aim }</IonCol>
                        <IonCol size='2'>{ val.community.name }</IonCol>
                        <IonCol size='2'>{ moment(val.date).format('DD/MM/YY') }</IonCol>
                        <IonCol size='2'>
                          <BlueButton
                            onClick={() => dispatchFocused({ type: FocusedActionKind.SELECT_ENGAGEMENT, engagement: val })}
                          >View
                          </BlueButton>
                        </IonCol>
                      </IonRow>
                    ))
                  }
                  {
                    focusedNotes.length === 0 && (
                      <p style={{ textAlign: 'center' }}>No engagement notes available</p>
                    )
                  }
                </div>
              </div>
            </>
            <IonRow className='ion-justify-content-end'>
              <IonButton onClick={() => handleAddSelector()} style={{ '--background': '#0C9500', marginTop: '1.4em' }}>Add Engagement Note</IonButton>
            </IonRow>
          </OverlayContainer>
        </>
      )
    case 'Notes':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Home')
                  }}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Governance')
                  }}
                >Governance
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', fontWeight: 'bold', color: '#8E151F',
                  }}
                  onClick={() => {
                    setView('Notes')
                  }}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Flashpoints')
                  }}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('StakeholderMap')
                  }}
                >Stakeholder Map
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Stakeholders')
                  }}
                >Stakeholders
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Issues')
                  }}
                >Issues
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Engagement Notes')
                  }}
                >Engagement Notes
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })}>Close X</SimpleButton>
            </IonRow>
            <>
              <div style={{ height: '75%', overflow: 'auto', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <IonRow style={{ padding: '10px' }} className='terrain-list-title ion-justify-content-between ion-align-items-center'>
                  <IonCol size='1'>
                    <h5 style={{ fontSize: 16, color: '#326771' }}>Date</h5>
                  </IonCol>
                  <IonCol size='1.5'>
                    <h5 style={{ fontSize: 16, color: '#326771' }}>Submitted</h5>
                  </IonCol>
                  <IonCol size='2'>
                    <h5 style={{ fontSize: 16, color: '#326771' }}>Subject</h5>
                  </IonCol>
                  <IonCol size='7'>
                    <h5 style={{ fontSize: 16, color: '#326771' }}>Content</h5>
                  </IonCol>
                  <IonCol />
                </IonRow>
                <div style={{ flex: 1, overflow: 'auto' }}>
                  {focusedComm?.notes?.length === 0 ? (
                    <p style={{ textAlign: 'center' }}>No Notes available</p>
                  ) : (
                    focusedComm?.notes?.map((note, index) => (
                      editing ? (
                        <IonRow
                          className='ion-justify-content-between terrain-register-row ion-align-items-center'
                          style={{ backgroundColor: getColor(index) }}
                          key={note.id}
                        >
                          <IonCol size='1' style={{ fontSize: '13px', color: '#737475' }}>{moment(note.updated_at).format('DD/MM/YY')}</IonCol>
                          <IonCol size='1.5' style={{ fontSize: '13px', color: '#737475' }}>{getNoteUser(note.user_id).label}</IonCol>
                          <IonCol size='2' style={{ fontSize: '13px', color: '#737475' }}>{note.subject}</IonCol>
                          <IonCol size='7' style={{ fontSize: '13px', color: '#737475' }}>{note.content}</IonCol>
                          <IonCol size='0.5'>
                            <BlueButton onClick={() => setEditing(false)}>Edit</BlueButton>
                          </IonCol>
                        </IonRow>
                      ) : (
                        <IonRow
                          className='ion-justify-content-between terrain-register-row ion-align-items-center'
                          style={{ backgroundColor: getColor(index) }}
                          key={note.id}
                        >
                          <IonCol size='1' style={{ fontSize: '13px', color: '#737475' }}>{moment(note.updated_at).format('DD/MM/YY')}</IonCol>
                          <IonCol size='1.5' style={{ fontSize: '13px', color: '#737475' }}>{getNoteUser(note.user_id).label}</IonCol>
                          <IonCol size='2' style={{ fontSize: '13px', color: '#737475' }}>{note.subject}</IonCol>
                          <IonCol size='7' style={{ fontSize: '13px', color: '#737475' }}>{note.content}</IonCol>
                          <IonCol size='0.5'>
                            <BlueButton onClick={() => { setView('View Note'); setSelectedNote(note); setSubject(note.subject); setContent(note.content) }}>View</BlueButton>
                          </IonCol>
                        </IonRow>
                      )
                    ))
                  )}
                </div>
              </div>
              <IonRow className='ion-justify-content-end'>
                <IonButton onClick={() => setView('Add Note')} style={{ '--background': '#0C9500', marginTop: '1.4em' }}>Add Note</IonButton>
              </IonRow>
            </>
          </OverlayContainer>
        </>
      )
    case 'Add Note':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Home')
                  }}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Governance')
                  }}
                >Governance
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', fontWeight: 'bold', color: '#8E151F',
                  }}
                  onClick={() => {
                    setView('Notes')
                  }}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Flashpoints')
                  }}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('StakeholderMap')
                  }}
                >Stakeholder Map
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Stakeholders')
                  }}
                >Stakeholders
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Issues')
                  }}
                >Issues
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Engagement Notes')
                  }}
                >Engagement Notes
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })}>Close X</SimpleButton>
            </IonRow>
            <>
              <div style={{ height: '79%', overflow: 'auto' }}>
                <IonRow className='ion-justify-content-between ion-align-items-center'>
                  <h5 style={{ marginLeft: '2em', fontSize: 16, color: '#326771' }}>{ `${focusedComm.name} - Add Note` }</h5>
                </IonRow>
                <IonRow style={{ marginLeft: '2em', height: '20%' }}>
                  <IonCol size='12'>
                    <h5 style={{ color: '#4197A9', fontSize: 13, marginBottom: '4px' }}>Subject</h5>
                    <input
                      type='text'
                      value={subject}
                      onChange={handleSubjectChange}
                      style={{ width: '100%', fontSize: 13, color: '#737475', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                      placeholder='Enter Subject'
                    />
                  </IonCol>
                </IonRow>
                <IonRow style={{ marginLeft: '2em', marginTop: '2em', height: '59%' }}>
                  <IonCol size='12'>
                    <h5 style={{ color: '#4197A9', fontSize: 13, marginBottom: '4px' }}>Content</h5>
                    <textarea
                      value={content}
                      onChange={handleContentChange}
                      style={{ width: '100%', height: '59%', fontSize: 13, color: '#737475', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                      placeholder='Enter Content'
                    />
                  </IonCol>
                </IonRow>
              </div>
              <IonRow className='ion-justify-content-end'>
                <IonButton onClick={() => setView('Notes')} style={{ '--background': '#8E151F' }}>Back</IonButton>
                <IonButton onClick={handleAddNote} style={{ '--background': '#326771' }}>Submit</IonButton>
              </IonRow>
            </>
          </OverlayContainer>
        </>
      )
    case 'View Note':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Home')
                  }}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Governance')
                  }}
                >Governance
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', fontWeight: 'bold', color: '#8E151F',
                  }}
                  onClick={() => {
                    setView('Notes')
                  }}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Flashpoints')
                  }}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('StakeholderMap')
                  }}
                >Stakeholder Map
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Stakeholders')
                  }}
                >Stakeholders
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Issues')
                  }}
                >Issues
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px',  color: '#28464B',
                  }}
                  onClick={() => {
                    setView('Engagement Notes')
                  }}
                >Engagement Notes
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })}>Close X</SimpleButton>
            </IonRow>
            <>
              <div style={{ height: '79%', overflow: 'auto' }}>
                <IonRow style={{ marginLeft: '2em' }}>
                  <IonCol>
                    <h5 style={{ color: '#4197A9', fontSize: 13, marginBottom: '4px' }}>Date and Submitted By</h5>
                    <p style={{ fontSize: 13, color: '#737475' }}>
                      {new Date(selectedNote.created_at).toLocaleDateString('en-GB')} by {getNoteUser(selectedNote.user_id).label}
                    </p>
                    <h5 style={{ color: '#4197A9', fontSize: 13, marginBottom: '4px' }}>Subject</h5>
                    {editing ? (
                      <input
                        type='text'
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        style={{ width: '100%', fontSize: 13, color: '#737475', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                      />
                    ) : (
                      <p style={{ fontSize: 13, color: '#737475' }}>{selectedNote.subject}</p>
                    )}
                  </IonCol>
                </IonRow>
                <IonRow style={{ marginLeft: '2em', height: '59%' }}>
                  <IonCol size='12'>
                    <h5 style={{ color: '#4197A9', fontSize: 13, marginBottom: '4px' }}>Content</h5>
                    {editing ? (
                      <textarea
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        style={{ width: '100%', height: '100%', fontSize: 13, color: '#737475', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                      />
                    ) : (
                      <div style={{ width: '100%', height: '100%', fontSize: 13, color: '#737475', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', overflowY: 'auto', backgroundColor: '#f9f9f9' }}>
                        {selectedNote.content}
                      </div>
                    )}
                  </IonCol>
                </IonRow>
              </div>
              { editing ? (
                <IonRow className='ion-justify-content-end'>
                  <IonButton onClick={() => setEditing(false)} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
                  <IonButton onClick={handleEditNote} style={{ '--background': '#326771' }}>Save</IonButton>
                </IonRow>
              ) : (
                <IonRow className='ion-justify-content-end'>
                  <IonButton onClick={() => setView('Notes')} style={{ '--background': '#8E151F' }}>Back</IonButton>
                  <IonButton onClick={() => setEditing(false)} style={{ '--background': '#326771' }}>Edit</IonButton>
                </IonRow>
              )}
            </>
          </OverlayContainer>
        </>
      )
  }
}

interface Props {
  elem: (Flashpoint | Stakeholder | EngagementNote | TerrainIssue)
}

const FactorIcon : FC<Props> = ({ elem }) => {
  if ((elem as Flashpoint).status) {
    return (
      <img src={FlashpointIcon} alt='Flashpoint icon' />
    )
  }

  if ((elem as Stakeholder).alias) {
    return (
      <img src={StakeholderIcon} alt='Stakeholder icon' />
    )
  }

  if ((elem as EngagementNote).aim) {
    return (
      <img src={EngagementIcon} alt='Engagement icon' />
    )
  }

  return (
    <img src={IssueIcon} alt='Issue icon' />
  )
}

export default CommunityProfile
