import {
  Feature, FeatureCollection, LineString, Point, Polygon, Position,
} from '@turf/turf'
import { ControlMeasure } from '../riskregister/types/RiskAssessments'
import { JourneyChapter } from './BackendTypes'
import { ChapterType } from './GeoJSONTypes'
import {
  AlertState, FlashpointStatus, IncidentCause, JourneyStatus, POIType, RestrictionZoneGeoJSON, RestrictionZoneType, SafetyStatus, User,
} from './GlobalTypes'

export interface CountryAreas {
  country: string,
  counties: County[],
  regions: ASRegion[],
}

export interface BackendCountryAreas {
  country: string,
  counties: County[],
  regions: Region[],
}

export interface County {
  id: number,
  name: string,
  shape_id: string,
  created_at: string,
  updated_at: string,
  alert_state: AlertState,
  region_id: number,
}

export interface StakeholderGroup {
  id: number,
  name: string
}

export interface Stakeholder {
  id: number,
  name: string,
  alias: string
  type: 'Organisation' | 'Individual',
  role: string,
  organisations: {
    id: number,
    name: string
  }[],
  areas?: (Community & { quadrant: StakeholderQuadrant })[],
  whatsapp_number: string,
  phone_number: string,
  email: string,
  analysis: string,
  groups: StakeholderGroup[],
  leaders: any[],
  parents: any[],
  subdivisions: any[],
  managements: any[],
}

export enum StakeholderQuadrant {
  HIGH_LOW = 'high_low',
  HIGH_HIGH = 'high_high',
  LOW_LOW = 'low_low',
  LOW_HIGH = 'low_high',
}

export interface BackendFlashpoint {
  id: number,
  additional_manifestations: {
    id: number,
    name: string
  }[],
  issues: IncidentCause[],
  flashpoint_groups: {
    id: number,
    group_type: 'primary' | 'secondary' | 'tertiary',
    stakeholder: Stakeholder,
    quadrant: StakeholderQuadrant,
  }[],
  areas: Community[],
  comments: string,
  description: string,
  ongoing: boolean,
  primary_manifestation: string,
  status: FlashpointStatus,
  title: string,
  triggers: string
}

export interface Community {
  governors: any
  head_id: number
  parent_id: number
  id: number,
  name: string,
  description: string,
  geoData: Feature<Polygon>,
}

export interface Flashpoint {
  id: number,
  title: string,
  description: string,
  triggers: string,
  status: FlashpointStatus,
  primaryManifestation: string,
  additionalManifestation: { id: number, name: string }[],
  ongoing: boolean,
  issues: IncidentCause[],
  primary_groups?: (Stakeholder & { quadrant: StakeholderQuadrant })[],
  secondary_groups?: (Stakeholder & { quadrant: StakeholderQuadrant })[],
  tertiary_groups?: (Stakeholder & { quadrant: StakeholderQuadrant })[],
  relevant_areas: { id: number, name: string }[],
  comments: string
}

export interface InternalProject {
  id: number,
  title: string,
  status: string,
  category: string,
  stakeholders: (Stakeholder & { quadrant: StakeholderQuadrant })[]
}

export enum CampaignType {
  SURVEY = 'survey',
  BULLETIN = 'bulletin',
}

export enum AnswerType {
  YES_NO = 'yes_no',
  OPTIONS = 'options',
}

export enum CampaignStatus {
  SCHEDULED = 'scheduled',
  COMPLETED = 'completed',
}

export interface RecipientList {
  id: number,
  name: string,
  stakeholders: Stakeholder[],
}

export interface EngagementCampaign {
  id: number,
  title: string,
  type: CampaignType,
  recipient_lists: RecipientList[],
  created_at: string,
  launch_date: string,
  answer_type?: AnswerType,
  status: CampaignStatus,
  message: string
  option_1: string,
  option_2: string,
  option_3?: string,
  option_4?: string,
}

export interface StakeholderMessage {
  id: number,
  content: string,
  user?: User,
  created_at: string,
}

export interface StakeholderChat {
  id: number,
  recipient: Stakeholder,
  latestMessage?: StakeholderMessage,
  created_at: string,
  isSMS: boolean,
}

export interface TerrainPOI {
  poi_areas: any
  id: number,
  geoData: Feature<Point> | Feature<LineString> | Feature<Polygon>,
  type: POIType,
  name: string,
  description: string,
  stakeholders: Stakeholder[],
  areas: Community[]
  category: string,
  created_at: string,
  updated_at: string,
}

export interface SafetyUser extends User {
  safety: {
    level: SafetyStatus,
    created_at: string,
    updated_at: string,
    latitude: number,
    longitude: number,
    county: {
      id: number,
      name: string,
    }
  }
}

export interface Region {
  id: number,
  name: string,
  country_id: number,
  shape_id: string,
  created_at: string,
  updated_at: string,
}

export interface ASRegion extends Region {
  avg_as: AlertState,
}

export interface ConsultantCounty extends County {
  crowdInsights: AlertState,
  avgSuggestion: AlertState,
  suggestions: []
}

export enum IncidentTimeFilter {
  LAST_WEEK = 'last_week',
  LAST_MONTH = '1month',
  SIX_MONTHS = '6month',
  LAST_YEAR = '1year',
  ALL_FETCH = 'all',
}

export interface Incident {
  id: number,
  description: string,
  latitude: number,
  longitude: number,
  reported: string,
  incident_type: string,
  fatalities: number,
  nearest: string,
  user_id: number,
  created_at: string,
  updated_at: string,
}

export const IncidentTimesOrder : IncidentTimeFilter[] = [IncidentTimeFilter.LAST_WEEK,
  IncidentTimeFilter.LAST_MONTH, IncidentTimeFilter.SIX_MONTHS, IncidentTimeFilter.LAST_YEAR, IncidentTimeFilter.ALL_FETCH]

export interface FetchIncidentsConfig {
  time_from: IncidentTimeFilter,
  time_to: string,
}

export interface BackendJourney {
  id: number,
  name: string,
  created_at: string,
  updated_at: string,
  submitter: User,
  approver: User,
  purpose: string,
  departure_time: string,
  departure_location: string,
  leader_phone: string,
  driver_name: string,
  driver_phone: string,
  vehicle_type: string,
  vehicle_reg: string,
  emergency_sat: string,
  emergency_other: string,
  arrival_time: string,
  arrival_location: string,
  passengers: string,
  passenger_hotel: string,
  passenger_location: string,
  passenger_contact: string,
  driver_hotel: string,
  driver_location: string,
  driver_contact: string,
  status: JourneyStatus,
  chapters: JourneyChapter[],
  journey_stages: {
    path_id: number,
    created_at: string,
  }[],
  leader: User,
}

export interface Journey extends BackendJourney {
  routes: {
    id: number,
    name: string,
  }[],
  firstPoint: Position,
}

export interface Route {
  id: number,
  geoData: Feature<LineString>,
  name: string,
}

export interface BackendRestrictionZone {
  id: number,
  geoData: string,
  zone_type: RestrictionZoneType,
  description: string,
  created_at: string,
  check_in_days: number,
  county_data: {
    id: number,
    name: string,
    alert_state: AlertState,
    sc_alert: AlertState,
  },
  user: User,
  box_measures: {
    control_measure_id: number,
    created_at: string,
  }[],
  zone_underlyings: {
    cause_id: number,
    created_at: string,
  }[],
  zone_threats: {
    threat: string
  }[]
}

export interface RestrictionZone extends BackendRestrictionZone {
  users?: User[]
  controlMeasures: ControlMeasure[],
  geodata: RestrictionZoneGeoJSON,
  underlyings: IncidentCause[],
  threats: string[],
}

export enum AirJourneyStatus {
  NOT_SUBMITTED = 'Not-Submitted',
  PENDING = 'Pending',
  DECLINED = 'Declined',
  APPROVED = 'Approved',
  CANCEL = 'Cancel',
}

export enum AirJourneyStage {
  PRE_TRAVEL = 'Pre-Travel',
  FLIGHT_REQUEST = 'Flight-Request',
  PRE_DEPARTURE = 'Pre-Departure',
  IN_TRANSIT = 'In-Transit',
  ABORTED = 'Aborted',
  COMPLETE = 'Complete',
}

export interface AirJourney {
  id: number,
  submitter: User,
  approver: User,
  purpose: string,
  from: string,
  to: string,
  departure_date: string,
  comment: string,
  airline_name: string,
  flignt_no: string,
  flight_departure_date: string,
  passengers: string,
  status: AirJourneyStatus,
  created_at: string,
  updated_at: string,
  stage: AirJourneyStage,
  fromLng: number,
  fromLat: number,
  toLng: number,
  toLat: number,
  story: JourneyChapter[],
  alert_state: AlertState,
  sc_level: AlertState,
  destinationCountyId: number,
}

export interface ChapterProperties {
  journey_id: number,
  type: ChapterType,
  id: number,
  user_id: number,
}

export type ChaptersGeoJSON = FeatureCollection<Point, ChapterProperties>

export type CommunitiesGeoJSON = FeatureCollection<Polygon, { id: number }>

export type EngagementsGeoJSON = FeatureCollection<Point, { id: number, name: string }>

export enum EngagementType {
  PHONE_CALL = 'Phone call',
  TEXT_MESSAGE_EXCHANGE = 'Text message exchange',
  FACE_TO_FACE_MEETING = 'Face-to-face meeting',
  VIDEO_CALL = 'Video call',
}

export enum EngagementSupport {
  STRONGLY_OPPOSED = 'Strongly opposed',
  SOMEWHAT_OPPOSED = 'Somewhat opposed',
  NORMAL = 'Normal',
  SOMEWHAT_IN_FAVOUR = 'Somewhat in favour',
  VERY_IN_FAVOUR = 'Very in favour',
}

export enum EngagementTone {
  AGGRESSIVE = 'Aggresive',
  ANNOYED = 'Annoyed',
  NORMAL = 'Normal',
  POSITIVE = 'Positive',
  ENTHUSIASTIC = 'Enthusiastic',
}

export interface EngagementNote {
  engagement_note_attendings: any
  id: number,
  subject: string,
  type: EngagementType,
  date: string,
  representatives: User[],
  community: { id: number, name: string },
  stakeholders: { id: number, name: string }[],
  orgs: { id: number, name: string }[],
  aim: string,
  tone: EngagementTone,
  support: EngagementSupport,
  summary: string,
  created_at: string,
  updated_at: string,
}

export enum TerrainType {
  QUESTION = 'Question',
  REQUEST_FOR_INFORMATION = 'Request for Information',
  GENERAL_PERCEPTION = 'General Perception',
  FRICTION = 'Friction',
  OTHER = 'Other',
}

export interface TerrainIssue {
  id: number,
  title: string,
  type: TerrainType,
  date: string,
  description: string,
  causes: IncidentCause[],
  stakeholders: { id: number, name: string }[],
  communities: { id: number, name: string }[]
}

export enum CommitmentType {
  SOCIAL_ECONOMIC_DEVELOPMENT = 'Social Economic Development',
  POLICY = 'Policy',
  CONTRACTUAL = 'Contractual',
  CUSTOMARY_COMMUNITARY = 'Customary/Communitary',
  REGULATORY = 'Regulatory',
  RESETTLEMENT = 'Resettlement',
  STAKEHOLDER_REQUEST = 'Stakeholder Request',
  VERBAL_COMMITMENT = 'Verbal Commitment by Staff',
  OTHER = 'Other',
}

export interface Commitment {
  id: number,
  title: string,
  commitment_type: CommitmentType,
  date: string,
  description: string,
  communities: { id: number, name: string }[],
  stakeholders: { id: number, name: string }[],
  issues: IncidentCause[],
  owner: User,
}
