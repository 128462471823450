/* eslint-disable react/no-array-index-key */
import { Icon } from '@iconify/react'
import { IonCol, IonRow, useIonAlert } from '@ionic/react'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import Select from 'react-select'
import Datetime from 'react-datetime'
import CreatableSelect from 'react-select/creatable'
import { FocusedActionKind } from '../../../hooks/terrain-mapping/helpers/StateReducers'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { ListContainer } from '../../consultant/StyledContainers'
import { TagButton } from '../../incident-management/StyledContainers'
import { SimpleButton } from '../../maps/StyledContainers'
import axios from '../../../utils/axios'
import {
  buttonSupports, buttonTones, buttonTypes, Details,
} from '../forms/EngagementNote'
import { BackButton, PopoverContainer, PopoverField } from '../StyledContainer'
import { User } from '../../types/GlobalTypes'
import { EngagementSupport, EngagementTone, EngagementType } from '../../types/OptimizedMaps'
import { useWorkspace } from '../../../hooks/useWorkspace'

const EngagementScreen : FC = () => {
  const {
    focusedElems, dispatchFocused, editing, users, updateEngagementNote, setLoading,
    communities, stakeholders, saving, setEditing, setSubmittedMessage, setSaving,
  } = useTerrainMapping()
  const { focusedEngagement } = focusedElems
  const [ionAlert] = useIonAlert()
  const { workspace } = useWorkspace()

  const [input, setInput] = useState<Details>({
    subject: '',
    date: moment(),
    representatives: [undefined],
    community: 0,
    stakeholders: [],
    aim: '',
    summary: '',
  })

  const chooseUserInvolved = (addUser: { value: User, label: string }, index: number) => {
    const copy = input.representatives.slice()
    copy.splice(index, 1, addUser)

    setInput({ ...input, representatives: copy })
  }

  const errorMessages = {
    subject: 'Engagement note must have a subject',
    //type: 'Engagement type not specified',
    //date: 'Date of the engagement not specified',
    //community: 'Engagement must have occurred at a given neighbourhood/community',
    //aim: 'Aim of engagement missing',
    //tone: 'Please specify the tone of the engagement',
    //support: 'Please specify the level of support toward the company',
    //summary: 'Summary of engagement missing',
  }

  const aims = [
    'Communicating information',
    'Facilitating decision-making',
    'Identifying and mitigating impacts and risks',
    'Incident and grievance management',
    'Land access',
    'Managing commitments',
    'Maximising opportunities',
    'Understanding perceptions / managing expectations'
  ].map((item) => ({ label: item, value: item }));
  // Now, you can use dropdownOptions for your dropdown component.

  const handleSubmit = () => {
    const keys = Object.keys(errorMessages)
    const findError = keys.find((val) => !input[val] || input[val] === '')

    if (!input.representatives.every((val) => val)) {
      ionAlert({
        header: 'Form Error',
        message: 'One or more representatives data seem to not be filled',
        buttons: [{ text: 'Ok' }],
      })
      return
    }

    if (findError) {
      ionAlert({
        header: 'Form Error',
        message: errorMessages[findError],
        buttons: [{ text: 'Ok' }],
      })
      return
    }

    const filteredRepresentatives = input.representatives.reduce<{ value: User, label: string }[]>((list, current) => {
      if (list.find(({ value }) => value.id === current.value.id)) {
        return list
      }
      return [...list, current]
    }, [])

    const selectedRepresentatives = filteredRepresentatives.map(({ value }) => value)

    const selectedOrgs = stakeholders.filter(({ id }) => input.orgs.find(({ value }) => value === id))
    const selectedStakeholders = stakeholders.filter(({ id }) => input.stakeholders.find(({ value }) => value === id))
    const community = communities.find(({ id }) => input.community === id)

    const removeStakeholders = focusedEngagement.stakeholders.filter(({ id }) => !selectedStakeholders.find((val) => val.id === id))
    const addStakeholders = selectedStakeholders.filter(({ id }) => !focusedEngagement.stakeholders.find((val) => val.id === id))

    const removeOrgs = focusedEngagement.engagement_note_attendings.filter(({ stakeholder_id }) => !selectedOrgs.find((val) => val.id === stakeholder_id))
    const addOrgs = selectedOrgs.filter(({ id }) => !focusedEngagement.engagement_note_attendings.find((val) => val.stakeholder_id === id))

    const removeReps = focusedEngagement.representatives.filter(({ id }) => !selectedRepresentatives.find((val) => id === val.id))
    const addReps = selectedRepresentatives.filter(({ id }) => !selectedRepresentatives.find((val) => val.id === id))

    setLoading(true)
    axios.put('/api/v2/engagement_note/edit_note', {
      domain_id: workspace.id,
      note_id: focusedEngagement.id,
      subject: input.subject,
      type: input.type,
      date: input.date.format(),
      summary: input.summary,
      community_id: input.community,
      aim: input.aim,
      tone: input.tone,
      support: input.support,
      remove_stakeholders: removeStakeholders.map(({ id }) => id),
      add_stakeholders: addStakeholders.map(({ id }) => id),
      remove_orgs: removeOrgs.map(({ stakeholder_id }) => stakeholder_id),
      add_orgs: addOrgs.map(({ id }) => id),
      remove_reps: removeReps.map(({ id }) => id),
      add_reps: addReps.map(({ id }) => id),
    }).then(({ data }) => {
      if (!data.ok) {
        ionAlert({
          header: 'Server Error',
          message: data.message,
          buttons: [{ text: 'Ok' }],
        })
        return
      }
      updateEngagementNote({
        id: focusedEngagement.id,
        subject: input.subject,
        aim: input.aim,
        type: input.type as EngagementType,
        date: input.date.format(),
        representatives: selectedRepresentatives,
        community,
        engagement_note_attendings: selectedOrgs.map(({ id, name }) => ({ stakeholder_id: id, name })),
        orgs: selectedOrgs.map(({ id, name }) => ({ id, name })),
        stakeholders: selectedStakeholders.map(({ id, name }) => ({ id, name })),
        tone: input.tone as EngagementTone,
        support: input.support as EngagementSupport,
        summary: input.summary,
        created_at: moment().format(),
        updated_at: moment().format(),
      })
      setSubmittedMessage('Engagement note edited succesfully')
    }).catch(() => {
      ionAlert({
        header: 'Server Error',
        message: 'Unknown Server Error',
        buttons: [{ text: 'Ok' }],
      })
    }).finally(() => {
      setEditing(false)
      setSaving(false)
      setLoading(false)
    })
  }

  useEffect(() => {
    if (!editing) {
      return
    }

    setInput({
      subject: focusedEngagement.subject,
      date: moment(focusedEngagement.date),
      representatives: focusedEngagement.representatives?.map((val) => ({ value: val, label: `${val.first_name} ${val.final_name}` })),
      community: focusedEngagement?.community?.id,
      orgs: focusedEngagement.engagement_note_attendings?.map((attending) => stakeholders.find((stakeholder) => stakeholder.id === attending.stakeholder_id)).filter(Boolean).map(({ id, name }) => ({ value: id, label: name })),
      stakeholders: focusedEngagement.stakeholders?.map(({ id, name }) => ({ value: id, label: name })),
      aim: focusedEngagement.aim,
      summary: focusedEngagement.summary,
      tone: focusedEngagement.tone,
      support: focusedEngagement.support,
      type: focusedEngagement.type,
    })
  }, [editing])

  useEffect(() => {
    if (!saving) { return }
    handleSubmit()
  }, [saving])

  return (
    <ListContainer style={{ height: '100%', background: '#DCDCDC' }}>
      <div style={{ height: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
        <BackButton onClick={() => {
          dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })
          setEditing(false)
        }}
        >Back to List
        </BackButton>
        <PopoverContainer>
          <PopoverField>
            <h6>Subject</h6>
            {
              editing ? (
                <input
                  placeholder='Subject of engagement'
                  type='text'
                  value={input.subject}
                  onChange={(e) => setInput({ ...input, subject: e.target.value })}
                />
              ) : (
                <p>{ focusedEngagement.subject }</p>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Type</h6>
            {
              editing ? (
                <>
                  {
                    buttonTypes.map((val) => (
                      <TagButton
                        key={val}
                        onClick={() => setInput({ ...input, type: val })}
                        style={{ backgroundColor: (val === input.type) ? '#326771' : 'white', color: (val === input.type) ? 'white' : '#326771' }}
                      >
                        { val }
                      </TagButton>
                    ))
                  }
                </>
              ) : (
                <p>{ focusedEngagement.type }</p>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Date</h6>
            {
              editing ? (
                <Datetime
                  dateFormat='YYYY-MM-DD'
                  inputProps={{
                    placeholder: 'Select Date',
                    readOnly: true,
                    style: { backgroundColor: 'white' },
                  }}
                  value={input.date}
                  onChange={(e) => setInput({ ...input, date: e as moment.Moment })}
                />
              ) : (
                <p>{ focusedEngagement.date }</p>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Company Representatives</h6>
            {
              editing ? (
                <>
                  {
                    input.representatives.map((val, index) => (
                      <IonRow key={index} style={{ margin: '10px 0' }}>
                        <IonCol size='3'>
                          <Select
                            placeholder='Select user'
                            name='supervisor'
                            id='select-supervisor-container'
                            className='select-container'
                        // eslint-disable-next-line no-undef
                            value={val}
                            menuPortalTarget={document.body}
                            options={users.map((userVal) => ({ value: userVal, label: `${userVal.first_name} ${userVal.final_name}` }))}
                            onChange={(selected) => chooseUserInvolved(selected, index)}
                          />
                        </IonCol>
                        <IonCol size='3'>
                          <p className='incident-management-text-field'>{ val ? val.value.email : 'Email' }</p>
                        </IonCol>
                        <IonCol size='3'>
                          <p className='incident-management-text-field'>{ val && val.value.phone_number ? val.value.phone_number : 'Phone No.' }</p>
                        </IonCol>
                        <IonCol size='3'>
                          <p className='incident-management-text-field'>{ val && val.value.alternative_phone_number ? val.value.alternative_phone_number : 'Alternative Phone No.' }</p>
                        </IonCol>
                      </IonRow>
                    ))
                  }
                  <IonRow className='ion-justify-content-end'>
                    <SimpleButton
                      onClick={() => setInput({ ...input, representatives: [...input.representatives, undefined] })}
                    >
                      Add Person <Icon icon='ic:baseline-plus' style={{ marginLeft: '10px' }} />
                    </SimpleButton>
                  </IonRow>
                </>
              ) : (
                <>
                  {
                    focusedEngagement.representatives.length === 0 ? (
                      <p>No Representatives</p>
                    ) : (
                      <ul>
                        {
                          focusedEngagement.representatives.map(({ first_name, final_name, id }) => (
                            <li key={id}><p>{first_name} {final_name}</p></li>
                          ))
                        }
                      </ul>
                    )
                  }
                </>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Neighbourhood/Community</h6>
            {
              editing ? (
                <select
                  value={input.community}
                  onChange={(e) => setInput({ ...input, community: Number(e.target.value) })}
                  style={{ padding: '5px 10px' }}
                >
                  <option value={0} disabled>Select a community</option>
                  {
                  communities.map((val) => (
                    <option key={val.id} value={val.id}>{ val.name }</option>
                  ))
                }
                </select>
              ) : (
                <p>{ focusedEngagement?.community?.name }</p>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Attending Organisations</h6>
            {
              editing ? (
                <CreatableSelect
                  isMulti
                  placeholder='Select Organisations'
                  name='colors'
                  className='select-container'
                  id='journey-passengers'
              // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  options={stakeholders.filter(({ type }) => type === 'Organisation').map(({ id, name }) => ({ label: name, value: id }))}
                  value={input.orgs}
                  onChange={(selected) => setInput({ ...input, orgs: selected })}
                />
              ) : (
                <>
                  {
                    focusedEngagement.engagement_note_attendings?.length === 0 ? (
                      <p>No stakeholders</p>
                    ) : (
                      <ul>
                        {
                          focusedEngagement.engagement_note_attendings?.map((attending) => 
                            stakeholders.find((stakeholder) => stakeholder.id === attending.stakeholder_id),
                          ).filter(Boolean).map(({ name, id }) => (
                            <li key={id}><p>{ name }</p></li>
                          ))
                        }
                      </ul>
                    )
                  }
                </>

              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Attending Individuals</h6>
            {
              editing ? (
                <CreatableSelect
                  isMulti
                  placeholder='Select Individuals'
                  name='colors'
                  className='select-container'
                  id='journey-passengers'
              // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  options={stakeholders.filter(({ type }) => type === 'Individual').map(({ id, name }) => ({ label: name, value: id }))}
                  value={input.stakeholders}
                  onChange={(selected) => setInput({ ...input, stakeholders: selected })}
                />
              ) : (
                <>
                  {
                    focusedEngagement.stakeholders.length === 0 ? (
                      <p>No stakeholders</p>
                    ) : (
                      <ul>
                        {
                          focusedEngagement.stakeholders.map(({ name, id }) => (
                            <li key={id}><p>{ name}</p></li>
                          ))
                        }
                      </ul>
                    )
                  }
                </>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Aim</h6>
            {
              editing ? (
                <select
                  value={input.aim}
                  onChange={(e) => setInput({ ...input, aim: e.target.value })}
                  style={{ padding: '5px 10px' }}
                >
                  <option value='' disabled>Select the aim</option>
                  {aims.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : (
                <p>{ focusedEngagement.aim }</p>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Tone</h6>
            {
              editing ? (
                <>
                  {
                    buttonTones.map((val) => (
                      <TagButton
                        key={val}
                        onClick={() => setInput({ ...input, tone: val })}
                        style={{ backgroundColor: (val === input.tone) ? '#326771' : 'white', color: (val === input.tone) ? 'white' : '#326771' }}
                      >
                        { val }
                      </TagButton>
                    ))
                  }
                </>
              ) : (
                <p>{ focusedEngagement.tone }</p>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Support</h6>
            {
              editing ? (
                <>
                  {
                    buttonSupports.map((val) => (
                      <TagButton
                        key={val}
                        onClick={() => setInput({ ...input, support: val })}
                        style={{ backgroundColor: (val === input.support) ? '#326771' : 'white', color: (val === input.support) ? 'white' : '#326771' }}
                      >
                        { val }
                      </TagButton>
                    ))
                  }
                </>
              ) : (
                <p>{ focusedEngagement.support }</p>
              )
            }
          </PopoverField>
          <PopoverField className='terrain-form-field form-desc'>
            <h6>Summary</h6>
            {
              editing ? (
                <textarea value={input.summary} onChange={(e) => setInput({ ...input, summary: e.target.value })} />
              ) : (
                <p>{ focusedEngagement.summary }</p>
              )
            }
          </PopoverField>
        </PopoverContainer>
      </div>
    </ListContainer>
  )
}

export default EngagementScreen
