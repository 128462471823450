/* eslint-disable max-len */
/* eslint-disable default-case */
/* eslint-disable react/no-array-index-key */
import { IonButton, IonRow, IonCol, IonDatetime, IonContent, IonList } from '@ionic/react'
import React, { FC, useEffect, useState } from 'react'
import moment from 'moment'
import Select from 'react-select'
import Datetime from 'react-datetime'
import CreatableSelect from 'react-select/creatable'
import { Label } from 'recharts'
import axios from 'axios'
import { Icon } from '@iconify/react'
import { ObscureBackground, OverlayContainer } from '../GlobalContainers'
import { SimpleButton } from '../maps/StyledContainers'
import { BlueButton, TabOption, TagButton } from '../projects/StyledContainers'
import { useRiskRegister } from '../../hooks/risks/useRiskRegister'
import useApi from '../../hooks/testHooks'
import { useWorkspace } from '../../hooks/useWorkspace'
import { FlashpointStatus, IncidentCause, User } from '../types/GlobalTypes'
import { getColor } from '../maps/apps/AppMap'
import ExistingMeasures from './forms/assessment/ExistingMeasures'
import { CenterForm, ColumnContainer, MeasureContainer } from './StyledContainers'
import { SettingField } from '../settings/StyledContainers'
import { IssueTag } from '../terrain-mapping/StyledContainer'
import { Flashpoint, Stakeholder } from '../types/OptimizedMaps'
import { useAuth } from '../../hooks/useAuth'

const AssetProfileV2 : FC = () => {
  const {
    selectedAsset, setEditing, setShowSelectedAsset, editing, users, incidentTypes, assetTypes, setLoading, setSubmittedMessage, assets,
    rawMeasures, setSelectedAsset, stakeholders, communities, manifestations, causes, regions,
  } = useRiskRegister()

  const [view, setView] = useState<String>('Home')
  const { user } = useAuth()

  const categories = rawMeasures.map(({ category }) => category).filter((val, pos, arr) => arr.indexOf(val) === pos)

  const [selectedRegion, setSelectedRegion] = useState('')
  const [counties, setCounties] = useState([])
  const [selectedCounty, setSelectedCounty] = useState(selectedAsset.county_ids)

  const alertOptions = [
    {
      label: 'Level 1 - Caution',
      value: 1,
      definition: 'There is a low risk of possible terrorist activity or civil unrest affecting operations or personnel. Business as usual.',
    },
    {
      label: 'Level 2 - Warning',
      value: 2,
      definition: 'A general or localised threat situation, the nature and extent of which are unpredictable, and circumstances do not justify full implementation of higher alert measures. Local travel restrictions may be necessary.',
    },
    {
      label: 'Level 3 - Standfast',
      value: 3,
      definition: 'A foreseen event or situation that could impact on the security of operations. There is potential for the security situation in the area to deteriorate rapidly. Local lockdowns and / or severely restricted staff movements may be necessary. An evacuation may be required imminently.',
    },
    {
      label: 'Level 4 - Standby',
      value: 4,
      definition: 'A ‘threat to life’ incident is highly likely. Security conditions are hostile and approaching a level where operations are not possible. Critical risk to staff. Partial evacuation should be considered.',
    },
    {
      label: 'Level 5 - Evacuation',
      value: 5,
      definition: 'There is an immediate / direct threat to personnel; or armed conflict has occurred in the area. There are extreme risks to personnel and assets. Full evacuation should be considered.',
    },
  ]

  const flashStatusOptions = [
    { label: 'Low Condition', key: 'low', definition: 'The flashpoint is dormant, with no immediate threat of conflict. The situation remains stable, but periodic monitoring is advised.' },
    { label: 'Guarded Condition', key: 'guarded', definition: 'The flashpoint is active, but the risk of conflict is low. The situation remains stable, though ongoing observation and management are necessary to prevent escalation.' },
    { label: 'Elevated Condition', key: 'elevated', definition: 'The flashpoint is unstable and could escalate rapidly. Urgent action is needed to minimise or avoid conflict.' },
    { label: 'High Condition', key: 'high',definition: 'The flashpoint is highly unstable, and the risk of conflict is imminent.' },
    { label: 'Severe Condition', key: 'severe', definition: 'The flashpoint has escalated into active conflict.' },
  ]

  const getUser = () => {
    const user = users.find((e) => e.id === selectedAsset?.user_id)
    if (user) {
      return { value: user.id, label: `${user.first_name} ${user.final_name}` }
    }
    return { value: null, label: 'N/A' }
  }

  const getNoteUser = (user_id) => {
    const user = users.find((e) => e.id === user_id)
    if (user) {
      return { value: user.id, label: `${user.first_name} ${user.final_name}` }
    }
    return { value: null, label: 'N/A' }
  }

  const updateAdditionalManifest = (newVal: string, index: number) => {
    const copy = flashpointInput.additionalManifestations.slice()
    copy.splice(index, 1, newVal)

    setFlashpointInput({ ...flashpointInput, additionalManifestations: copy })
  }

  const getParent = () => {
    const parent = assets.find((e) => e.id === selectedAsset.parent_id)
    if (parent) {
      return { value: parent.id, label: parent.name }
    }
    return { value: null, label: 'N/A' }
  }

  type OptionType = {
    label: string;
    value: string;
  }

  const [owner, setOwner] = useState<any>(getUser)
  const [alert, setAlert] = useState(alertOptions.find((option) => option.value === selectedAsset.alert_state))
  const [comment, setComment] = useState<any>(selectedAsset.comment)
  const [date, setDate] = useState<any>(selectedAsset.review_date)
  const [factors, setFactors] = useState<{ label: string, value: string }[]>(selectedAsset.threats.map((threat) => ({ label: threat, value: threat })))
  const [name, setName] = useState<string>(selectedAsset.name)
  const [type, setType] = useState<OptionType>({ label: selectedAsset.asset_type, value: selectedAsset.asset_type })
  const [description, setDescription] = useState<string>(selectedAsset.description)
  const [associated, setAssociated] = useState<any>(selectedAsset.associated_asset_ids)
  const [selectedComms, setSelectedComms] = useState<any>(selectedAsset.comm_ids)
  const [subs, setSubs] = useState<any>(selectedAsset.sub_asset_ids)
  const [parent, setParent] = useState<any>(getParent)
  const [input, setInput] = useState<any>({ existingMeasures: selectedAsset.control_measures, newMeasures: [] })
  const [content, setContent] = useState<string>()
  const [subject, setSubject] = useState<string>()
  const [selectedNote, setSelectedNote] = useState<any>()
  const [selectedFlashpoint, setSelectedFlashpoint] = useState<any>()
  const [issues, setIssues] = useState<(IncidentCause & { selected: boolean })[]>([])
  const [flashpointInput, setFlashpointInput] = useState<any>({
    title: '',
    description: '',
    triggers: '',
    relevantAreas: [],
    comments: '',
    primaryManifestation: '',
    additionalManifestations: [''],
    primaryStakeholders: [],
    secondaryStakeholders: [],
    tertiaryStakeholders: [],
    status: '',
    assets: [],
  })

  useEffect(() => {
    console.log('input:', input)
    console.log('flashpointinput:', flashpointInput)
  }, [input, flashpointInput])

  const getAddRemove = (newRecords: (Stakeholder | { id: number, name: string } | IncidentCause)[], oldRecords:
  (Stakeholder | { id: number, name: string } | IncidentCause)[]) : { toRemove: number[], toAdd: number[] } => {
    const toAdd = newRecords.filter(({ id }) => !oldRecords.find((val) => val.value === id))
    const toRemove = oldRecords.filter(({ id }) => !newRecords.find((val) => val.value === id))

    return {
      toAdd: toAdd.map(({ id }) => id),
      toRemove: toRemove.map(({ id }) => id),
    }
  }

  const groupedMeasures = selectedAsset.control_measures.reduce((acc, measure) => {
    acc[measure.category] = acc[measure.category] || []
    acc[measure.category].push(measure)
    return acc
  }, {})

  const selectedCategories = Object.keys(groupedMeasures)

  // Break categories into chunks of 3 for each row
  const rows = []
  for (let i = 0; i < selectedCategories.length; i += 3) {
    rows.push(selectedCategories.slice(i, i + 3))
  }

  useEffect(() => {
    if (!editing) {
      setIssues(causes.map((val) => ({ ...val })))
      return
    }

    if (!selectedFlashpoint) {
      return
    }

    const stakeholders = {
      primaryStakeholders: selectedFlashpoint?.flashpoint_groups
        .filter(({ group_type }) => group_type === 'primary')
        .map(({ id, stakeholder }) => ({ value: id, label: stakeholder.name })),

      secondaryStakeholders: selectedFlashpoint?.flashpoint_groups
        .filter(({ group_type }) => group_type === 'secondary')
        .map(({ id, stakeholder }) => ({ value: id, label: stakeholder.name })),

      tertiaryStakeholders: selectedFlashpoint?.flashpoint_groups
        .filter(({ group_type }) => group_type === 'tertiary')
        .map(({ id, stakeholder }) => ({ value: id, label: stakeholder.name })),
    }

    setFlashpointInput({
      title: selectedFlashpoint.title,
      description: selectedFlashpoint.description,
      triggers: selectedFlashpoint.triggers,
      relevantAreas: selectedFlashpoint.areas.map(({ id, name }) => ({ value: id, label: name })),
      comments: selectedFlashpoint.comments,
      primaryManifestation: selectedFlashpoint.primary_manifestation,
      additionalManifestations: selectedFlashpoint.additional_manifestations.map(({ name }) => name),
      primaryStakeholders: stakeholders.primaryStakeholders,
      secondaryStakeholders: stakeholders.secondaryStakeholders,
      tertiaryStakeholders: stakeholders.tertiaryStakeholders,
      status: selectedFlashpoint.status,
      ongoing: selectedFlashpoint.ongoing ? 'Yes' : 'No',
    })
    setIssues(causes.map((val) => ({ ...val, selected: !!selectedFlashpoint.issues.find(({ id }) => val.id === id) })))
  }, [editing])

  const handleEditFlashpoint = () => {
    const stakeholdersPrimary = stakeholders.filter(({ id }) => flashpointInput.primaryStakeholders.find((val) => val.value === id))
    const stakeholdersSecondary = stakeholders.filter(({ id }) => flashpointInput.secondaryStakeholders.find((val) => val.value === id))
    const stakeholdersTertiary = stakeholders.filter(({ id }) => flashpointInput.tertiaryStakeholders.find((val) => val.value === id))

    const selectedIssues = issues.filter((val) => val.selected)

    const groups = {
      primaryStakeholders: selectedFlashpoint.flashpoint_groups
        .filter(({ group_type }) => group_type === 'primary')
        .map(({ id, stakeholder }) => ({ value: id, label: stakeholder.name })),

      secondaryStakeholders: selectedFlashpoint.flashpoint_groups
        .filter(({ group_type }) => group_type === 'secondary')
        .map(({ id, stakeholder }) => ({ value: id, label: stakeholder.name })),

      tertiaryStakeholders: selectedFlashpoint.flashpoint_groups
        .filter(({ group_type }) => group_type === 'tertiary')
        .map(({ id, stakeholder }) => ({ value: id, label: stakeholder.name })),
    }

    const primaryRecords = getAddRemove(stakeholdersPrimary, groups.primaryStakeholders)
    const secondaryRecords = getAddRemove(stakeholdersSecondary, groups.secondaryStakeholders)
    const tertiaryRecords = getAddRemove(stakeholdersTertiary, groups.tertiaryStakeholders)

    const issueRecords = getAddRemove(selectedIssues, selectedFlashpoint.issues)
    const communityRecords = getAddRemove(flashpointInput.relevantAreas.map(({ value, label }) => ({ id: value, name: label })), selectedFlashpoint.areas)

    const additionalMani = flashpointInput.additionalManifestations.filter((val) => val !== '').map((val, index) => ({ id: index, name: val }))

    setLoading(true)
    axios.put('/api/v2/flashpoint/edit_flashpoint', {
      flashpoint_id: selectedFlashpoint.id,
      domain_id: workspace.id,
      title: flashpointInput.title,
      description: flashpointInput.description,
      triggers: flashpointInput.triggers,
      status: flashpointInput.status,
      primary_manifestation: flashpointInput.primaryManifestation,
      ongoing: flashpointInput.ongoing === 'Yes',
      comments: flashpointInput.comments,

      primary_stakeholders_add: primaryRecords.toAdd,
      primary_stakeholders_remove: primaryRecords.toRemove,
      secondary_stakeholders_add: secondaryRecords.toAdd,
      secondary_stakeholders_remove: secondaryRecords.toRemove,
      tertiary_stakeholders_add: tertiaryRecords.toAdd,
      tertiary_stakeholders_remove: tertiaryRecords.toRemove,

      issues_add: issueRecords.toAdd,
      issues_remove: issueRecords.toRemove,
      community_add: communityRecords.toAdd,
      community_remove: communityRecords.toRemove,
      manifestations_add: additionalMani.filter(({ name }) => !selectedFlashpoint.additional_manifestations.find((val) => val.name === name)).map(({ name }) => name),
      manifestations_remove: selectedFlashpoint.additional_manifestations.filter(({ name }) => !additionalMani.find((val) => val.name === name)).map(({ name }) => name),
    }).then(({ data }) => {
      if (!data.ok) {
        console.error(data.message)
        return
      }

      const newFlashpoint : Flashpoint = {
        id: selectedFlashpoint.id,
        title: flashpointInput.title,
        description: flashpointInput.description,
        triggers: flashpointInput.triggers,
        status: flashpointInput.status as FlashpointStatus,
        primaryManifestation: flashpointInput.primaryManifestation,
        additionalManifestation: additionalMani,
        ongoing: (flashpointInput.ongoing === 'Yes'),
        issues: selectedIssues,
        primary_groups: stakeholdersPrimary,
        secondary_groups: stakeholdersSecondary,
        tertiary_groups: stakeholdersTertiary,
        relevant_areas: flashpointInput.relevantAreas.map(({ value, label }) => ({ id: value, name: label })),
        comments: flashpointInput.comments,
      }
      setSubmittedMessage('Flashpoint updated Successfully')
      // updateFlashpoint(newFlashpoint)
    }).catch(() => {
      setSubmittedMessage('Flashpoint updated Successfully')
    }).finally(() => {
      setEditing(false)
      setLoading(false)
    })
  }

  const apiHook = useApi()

  const { workspace } = useWorkspace()

  const handleSubjectChange = (event) => {
    setSubject(event.target.value)
  }

  const handleContentChange = (event) => {
    setContent(event.target.value)
  }

  const addManifestation = () => {
    const copy = flashpointInput.additionalManifestations.slice()
    copy.push('')

    setInput({ ...flashpointInput, additionalManifestations: copy })
  }

  const handleAddMeasures = () => {
    console.log('inputs:', input)
    const measureIds = input.existingMeasures.map((measure) => measure.id)
    setLoading(true)
    axios.post('/api/v1/asset/add_control_measures', {
      domain_id: workspace.id,
      asset_id: selectedAsset.id,
      measures: measureIds,
    }).then((response) => {
      // Assuming the response contains the newly created note's ID
      // const { id } = response.data // Extract the ID from the response
      // Create a new note object with the ID and other details
      // Update the selectedAsset.notes with the new note
      setSubmittedMessage('Control Measures Created Successfully')
      setLoading(false)
      setView('Control Measures')
      setSelectedAsset((prevState) => ({
        ...prevState,
        control_measures: input.existingMeasures,
      }))
    }).catch((error) => {
      console.error('Error adding Measure:', error)
      setLoading(false)
      setView('Control Measures')
    })
  }

  const handleEditNote = () => {
    setLoading(true)

    const updatedNote = {
      id: selectedNote.id,
      subject,
      content,
    }

    axios.put('/api/v1/asset/edit_note', {
      domain_id: workspace.id,
      subject,
      note_id: selectedNote.id,
      content,
    }).then(() => {
      setEditing(false)
      setSubmittedMessage('Asset Note Edited Successfully')
      setLoading(false)
      setView('Notes')
      // Update selectedAsset.notes with the edited note
      setSelectedAsset((prevState) => {
        const updatedNotes = prevState.notes.map((note) => (note.id === updatedNote.id ? updatedNote : note))
        return {
          ...prevState,
          notes: updatedNotes,
        }
      })
    })
  }

  const handleEdit = () => {
    setLoading(true)
    const threatValues = factors?.map((threat) => threat.value)
    const subsValues = subs?.map((sub) => sub.value)
    const associatedValues = associated?.map((e) => e.value)
    const commValues = selectedComms?.map((e) => e.value) || []
    const countyValues = selectedCounty?.map((e) => e.value) || []
    apiHook.editAsset({
      domain_id: workspace.id,
      asset_id: selectedAsset.id,
      name,
      longitude: selectedAsset.longitude,
      latitude: selectedAsset.latitude,
      asset_type: type.value,
      description,
      threats: threatValues,
      alert_state: alert?.value,
      comment,
      review_date: date,
      owner: owner ? owner.value : null,
      parent: parent ? parent.value : null,
      subs: subsValues,
      associated: associatedValues,
      county_id: selectedCounty,
      comms: commValues,
      counties: countyValues,
    }).then(() => {
      setEditing(false)
      setSubmittedMessage('Asset Updated Successfully')
      setLoading(false)
    })
  }

  const handleAddFlashpoint = () => {
    const selectedIssues = issues.filter((val) => val.selected)

    setLoading(true)
    axios.post('api/v2/flashpoint/create_flashpoint', {
      domain_id: workspace.id,
      title: flashpointInput.title,
      description: flashpointInput.description,
      triggers: flashpointInput.triggers,
      status: flashpointInput.status,
      primary_manifestation: flashpointInput.primaryManifestation,
      ongoing: flashpointInput.ongoing === 'Yes',
      comments: flashpointInput.comments,
      primary_stakeholders: flashpointInput.primaryStakeholders.map(({ value }) => value),
      secondary_stakeholders: flashpointInput.secondaryStakeholders.map(({ value }) => value),
      tertiary_stakeholders: flashpointInput.tertiaryStakeholders.map(({ value }) => value),
      manifestations: flashpointInput.additionalManifestations.filter((val) => val !== ''),
      issues: selectedIssues.map(({ id }) => id),
      community: flashpointInput.relevantAreas.map(({ value }) => value),
      assets: [selectedAsset.id],
    }).then(({ data }) => {
      if (data.message === 'you have not created a flashpoint.') {
        console.error(data.message)
        return
      }

      const stakeholdersPrimary = stakeholders.filter(({ id }) => flashpointInput.primaryStakeholders.find((val) => val.value === id))
      const stakeholdersSecondary = stakeholders.filter(({ id }) => flashpointInput.secondaryStakeholders.find((val) => val.value === id))
      const stakeholdersTertiary = stakeholders.filter(({ id }) => flashpointInput.tertiaryStakeholders.find((val) => val.value === id))

      const newFlashpoint : Flashpoint = {
        id: data.flashpoint.id,
        title: flashpointInput.title,
        description: flashpointInput.description,
        triggers: flashpointInput.triggers,
        status: flashpointInput.status as FlashpointStatus,
        primaryManifestation: flashpointInput.primaryManifestation,
        additionalManifestation: flashpointInput.additionalManifestations.map((val, index) => ({ id: index, name: val })),
        ongoing: (flashpointInput.ongoing === 'Yes'),
        issues: selectedIssues,
        primary_groups: stakeholdersPrimary,
        secondary_groups: stakeholdersSecondary,
        tertiary_groups: stakeholdersTertiary,
        flashpoint_groups: [...stakeholdersPrimary, ...stakeholdersSecondary, ...stakeholdersTertiary],
        relevant_areas: input.relevantAreas.map(({ value, label }) => ({ id: value, name: label })),
        comments: input.comments,
      }
      setSubmittedMessage('Flashpoint submitted succesfully')
    }).catch(() => {
      console.error('Flashpoint Error - submission failed')
    }).finally(() => {
      setLoading(false)
      setView('Flashpoints')
    })
  }

  const handleAddNote = () => {
    setLoading(true)
    axios.post('/api/v1/asset/add_note', {
      domain_id: workspace.id,
      subject,
      asset_id: selectedAsset.id,
      content,
    }).then((response) => {
      // Assuming the response contains the newly created note's ID
      const { id } = response.data // Extract the ID from the response
      // Create a new note object with the ID and other details
      const newNote = {
        id, // Use the ID from the response
        subject,
        content,
        created_at: new Date().toISOString(), // Or any other fields you want to set
        updated_at: new Date().toISOString(), // Same as above
        user_id: user.user_id,
      }
      // Update the selectedAsset.notes with the new note
      setSelectedAsset((prevState: AssetList) => ({...prevState, notes: [...prevState.notes, newNote]}))
      setEditing(false)
      setSubmittedMessage('Asset Note Created Successfully')
      setLoading(false)
      setView('Notes')
    }).catch((error) => {
      console.error('Error adding note:', error)
      setLoading(false)
    })
  }

  const getAlertLabel = (value) => {
    const alertOption = alertOptions.find((option) => option.value === value)
    return alertOption ? alertOption.label : 'Unknown'
  }

  const getStatusLabel = (value) => {
    const alertOption = flashStatusOptions.find((option) => option.key === value)
    return alertOption ? alertOption.label : 'Unknown'
  }

  const getAlertDefinition = (value) => {
    const alertOption = alertOptions.find((option) => option.value === value)
    return alertOption ? alertOption.definition : 'Unknown'
  }

  const getStatusDefinition = (value) => {
    const alertOption = flashStatusOptions.find((option) => option.key === value)
    return alertOption ? alertOption.definition : 'Unknown'
  }

  const selectCause = (index: number) => {
    const copy = issues.slice()
    copy.splice(index, 1, { ...copy[index], selected: !copy[index].selected })

    setIssues(copy)
  }

  const getAlertStyle = (value) => {
    switch (value) {
      case 1:
        return { backgroundColor: '#14C105', color: 'white' }
      case 2:
        return { backgroundColor: '#349DFF', color: 'white' }
      case 3:
        return { backgroundColor: '#FFE500', color: 'black' }
      case 4:
        return { backgroundColor: '#FF8A00', color: 'white' }
      case 5:
        return { backgroundColor: '#FF8A00', color: 'white' }
      default:
        return { backgroundColor: 'white', color: 'black' }
    }
  }

  
  const getStatusStyle = (value) => {
    switch (value) {
      case 'low':
        return { backgroundColor: '#14C105', color: 'white' }
      case 'guarded':
        return { backgroundColor: '#349DFF', color: 'white' }
      case 'elevated':
        return { backgroundColor: '#FFE500', color: 'black' }
      case 'high':
        return { backgroundColor: '#FF8A00', color: 'white' }
      case 'severe':
        return { backgroundColor: '#FF8A00', color: 'white' }
      default:
        return { backgroundColor: 'white', color: 'black' }
    }
  }

  /* Add measure */
  const handleDropAdd = (e) => {
    /* Get measure name */
    const measure = JSON.parse(e.dataTransfer.getData('measure'))

    if (input?.existingMeasures.find((val) => val.id === measure.id)) return
    const copy = input?.existingMeasures.slice()
    copy.push(measure)

    if (editing && Object.keys(input?.removeNew).find((val) => val === measure.id)) {
      setInput({ ...input, removeNew: { ...input?.removeNew, [measure.id]: true }, existingMeasures: copy })
      return
    }

    if (editing && Object.keys(input?.removeExisting).find((val) => val === measure.id)) {
      setInput({ ...input, removeExisting: { ...input?.removeExisting, [measure.id]: true }, existingMeasures: copy })
      return
    }
    setInput({ ...input, existingMeasures: copy })
  }

  /* Remove measure */
  const handleDropRemove = (e) => {
    /* Get measure name */
    const measure = JSON.parse(e.dataTransfer.getData('measure'))

    const index = input?.existingMeasures.findIndex((val) => val.id === measure.id)
    if (index < 0) return

    const copy = input?.existingMeasures.slice()
    copy.splice(index, 1)

    if (editing && Object.keys(input?.removeNew).find((val) => val === measure.id)) {
      setInput({ ...input, removeNew: { ...input?.removeNew, [measure.id]: false }, existingMeasures: copy })
      return
    }

    if (editing && Object.keys(input?.removeExisting).find((val) => val === measure.id)) {
      setInput({ ...input, removeExisting: { ...input.removeExisting, [measure.id]: false }, existingMeasures: copy })
      return
    }
    setInput({ ...input, existingMeasures: copy })
  }

  useEffect(() => {
    if (selectedAsset.county_ids && selectedAsset.county_ids.length > 0) {
      // Find the counties based on county_ids
      const counties = regions.flatMap((region) => region.counties).filter((c) => selectedAsset.county_ids.includes(c.id))
      if (counties.length > 0) {
        // Find the regions based on region_id from the counties
        const regionsSet = new Set(counties.map((county) => county.region_id))
        const selectedRegions = regions.filter((region) => regionsSet.has(region.id))

        // Set the selected regions and counties
        setSelectedRegion(selectedRegions.map((region) => region.id.toString()))
        setCounties(counties)
        setSelectedCounty(counties.map((county) => county.id.toString()))
      }
    }
  }, [selectedAsset.county_ids, regions])

  const handleRegionChange = (selected) => {
    console.log('handle region is occuring')
    const regionId = selected.value
    setSelectedRegion(selected)
    const selectedRegion = regions.find((region) => region.id === parseInt(regionId))
    setCounties(selectedRegion ? selectedRegion.counties : [])
  }

  const countyRegionMap = new Map()
  regions.forEach((region) => {
    region.counties.forEach((county) => {
      countyRegionMap.set(county.id, { countyName: county.name, regionName: region.name })
    })
  })

  switch (view) {
    case 'Home':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#8E151F', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Home')
                  }}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Notes')
                  }}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Threat')
                  }}
                >Threat
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Flashpoints')
                  }}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Control Measures')
                  }}
                >Control Measures
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Risk Register')
                  }}
                >Risk Register
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => { setShowSelectedAsset(false); setEditing(false) }}>Close X</SimpleButton>
            </IonRow>
            {editing ? (
              <>
                <IonRow className='ion-justify-content-between ion-align-items-center'>
                  <input style={{ padding: '10px 0', marginLeft: '2em', marginBottom: '4px' }} placeholder='Asset Name' type='text' value={name} onChange={(e) => setName(e.target.value)} />
                </IonRow>
                <div style={{ height: '67%', overflow: 'auto' }}>
                  <IonRow style={{ marginLeft: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Asset Type</h5>
                      <Select
                        placeholder='Select Asset Type'
                        name='alert'
                        id='select-alert-container'
                        className='select-container'
                          // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        options={assetTypes.map((type) => ({ value: type, label: type }))}
                        onChange={(selected) => setType(selected)}
                        value={type}
                      />
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Region/State</h5>
                      <CreatableSelect
                        placeholder='Select Region'
                        name='colors'
                        className='select-container'
                        id='region'
                          // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        options={regions.map(({ id, name }) => ({ label: name, value: id }))}
                        value={selectedRegion}
                        onChange={(selected) => handleRegionChange(selected)}
                      />
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>County</h5>
                      <CreatableSelect
                        isMulti
                        placeholder='Select County'
                        name='colors'
                        className='select-container'
                        id='county'
                          // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        options={counties.map(({ id, name }) => ({ label: name, value: id }))}
                        value={selectedCounty}
                        onChange={(selected) => setSelectedCounty(selected)}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Parent Asset</h5>
                      <Select
                        placeholder='Select Parent Asset'
                        name='parent'
                        id='select-parent-container'
                        className='select-container'
                          // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        options={assets.map((asset) => ({ value: asset.id, label: asset.name }))}
                        onChange={(selected) => setParent(selected)}
                        style={{ width: '50%' }}
                        value={parent}
                      />
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Sub Assets</h5>
                      <Select
                        isMulti
                        placeholder='Select Sub Assets'
                        name='subasset'
                        id='select-subasset-container'
                        className='select-container'
                          // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        options={assets.map((asset) => ({ label: asset.name, value: asset.id }))}
                        onChange={(selected) => setSubs(selected)}
                        style={{ width: '50%' }}
                        value={subs}
                      />
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Associated Assets</h5>
                      <Select
                        isMulti
                        placeholder='Select Associated Assets'
                        name='associated'
                        id='select-associated-container'
                        className='select-container'
                          // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        options={assets.map((asset) => ({ label: asset.name, value: asset.id }))}
                        onChange={(selected) => setAssociated(selected)}
                        style={{ width: '50%' }}
                        value={associated}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Communities</h5>
                      <Select
                        isMulti
                        placeholder='Select Communities'
                        name='associated'
                        id='select-associated-container'
                        className='select-container'
                          // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        options={communities.map((comm) => ({ label: comm.name, value: comm.id }))}
                        onChange={(selected) => setSelectedComms(selected)}
                        style={{ width: '50%' }}
                        value={selectedComms}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Description</h5>
                      <textarea
                        style={{ height: '5em', width: '100%' }}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder='Enter description'
                      />
                    </IonCol>
                  </IonRow>
                </div>
                <IonRow className='ion-justify-content-end'>
                  <IonButton onClick={handleEdit} style={{ '--background': '#326771' }}>Save</IonButton>
                  <IonButton onClick={() => setEditing(false)} style={{ '--background': '#326771' }}>Cancel</IonButton>
                </IonRow>
              </>
            ) : (
              <>
                <IonRow className='ion-justify-content-between ion-align-items-center'>
                  <h5 style={{ marginLeft: '2em', fontSize: 16, color: '#326771' }}>{ name }</h5>
                </IonRow>
                <div style={{ height: '70%', overflow: 'auto' }}>
                  <IonRow style={{ marginLeft: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Asset Type</h5>
                      <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{ type.label }</p>
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Region/State</h5>
                      <ul style={{ margin: 0, padding: 0, listStyleType: 'disc', paddingLeft: '20px' }}>
                        {selectedAsset.county_ids?.map((comm) => {
                          const countyInfo = countyRegionMap.get(comm)
                          return (
                            <li key={comm} style={{ fontSize: 13, color: '#737475' }}>
                              {countyInfo
                                ? `${countyInfo.regionName}`
                                : 'Unknown Region'}
                            </li>
                          )
                        })}
                      </ul>
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>County</h5>
                      <ul style={{ margin: 0, padding: 0, listStyleType: 'disc', paddingLeft: '20px' }}>
                        {selectedAsset.county_ids?.map((comm) => {
                          const countyInfo = countyRegionMap.get(comm)
                          return (
                            <li key={comm} style={{ fontSize: 13, color: '#737475' }}>
                              {countyInfo
                                ? `${countyInfo.countyName}`
                                : 'Unknown County '}
                            </li>
                          )
                        })}
                      </ul>
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Parent Asset</h5>
                      <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{ parent.label }</p>
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Sub Assets</h5>
                      <ul style={{ margin: 0, padding: 0, listStyleType: 'disc', paddingLeft: '20px' }}>
                        {subs.map((sub, index) => {
                          const asset = assets.find((asset) => asset.id === sub.value)
                          return (
                            <li key={index} style={{ fontSize: 13, color: '#737475' }}>
                              {asset ? `${asset.name}, ${asset.asset_type}` : 'N/A'}
                            </li>
                          )
                        })}
                      </ul>
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Associated Assets</h5>
                      <ul style={{ margin: 0, padding: 0, listStyleType: 'disc', paddingLeft: '20px' }}>
                        {associated.map((assoc, index) => {
                          const asset = assets.find((asset) => asset.id === assoc.value)
                          return (
                            <li key={index} style={{ fontSize: 13, color: '#737475' }}>
                              {asset ? `${asset.name}, ${asset.asset_type}` : 'N/A'}
                            </li>
                          )
                        })}
                      </ul>
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Communities</h5>
                      <ul>
                        {selectedAsset.comm_ids?.map((val) => {
                          const community = communities.find((comm) => comm.id === val)
                          return (
                            <li key={val} style={{ fontSize: 13, color: '#737475' }}>
                              {val.content}
                              {community ? community.name : 'N/A'}
                            </li>
                          )
                        })}
                      </ul>
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Description</h5>
                      <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{ description }</p>
                    </IonCol>
                  </IonRow>
                </div>
                <IonRow className='ion-justify-content-end'>
                  <IonButton onClick={() => setEditing(true)} style={{ '--background': '#326771' }}>Edit</IonButton>
                </IonRow>
              </>
            )}
          </OverlayContainer>
        </>
      )
    case 'Threat':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Home')
                  }}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Notes')
                  }}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#8E151F', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Threat')
                  }}
                >Threat
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Flashpoints')
                  }}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Control Measures')
                  }}
                >Control Measures
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Risk Register')
                  }}
                >Risk Register
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => { setShowSelectedAsset(false); setEditing(false) }}>Close X</SimpleButton>
            </IonRow>
            {editing ? (
              // Render editing view
              <>
                <IonRow className='ion-justify-content-between ion-align-items-center'>
                  <h5 style={{ marginLeft: '2em', fontSize: 16, color: '#326771' }}>{ selectedAsset.name }</h5>
                </IonRow>
                <div style={{ height: '70%', overflow: 'auto' }}>
                  <IonRow style={{ marginLeft: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Review Date</h5>
                      <Datetime
                        dateFormat='DD/MM/YY'
                        inputProps={{
                          placeholder: 'Start Date',
                          readOnly: true,
                          style: { backgroundColor: 'white' },
                        }}
                        isValidDate={(current) => current.isAfter(moment())}
                        name='date'
                        value={moment(date).format('DD/MM/YY')}
                        onChange={(e) => setDate(e)}
                        timeFormat={false}
                      />
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Owner</h5>
                      <Select
                        placeholder='Select user'
                        name='owner'
                        id='select-owner-container'
                        className='select-container'
                          // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        options={users.map((user) => ({ value: user.id, label: `${user.first_name} ${user.final_name}` }))}
                        onChange={(selected) => setOwner(selected)}
                        style={{ width: '50%' }}
                        value={owner}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '0.8em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Asset Alert State</h5>
                      <Select
                        placeholder='Select Alert State'
                        name='alert'
                        id='select-alert-container'
                        className='select-container'
                          // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        options={alertOptions}
                        onChange={(selected) => setAlert(selected)}
                        value={alert}
                      />
                      <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{getAlertDefinition(alert?.value)}</p>
                    </IonCol>
                    <IonCol>
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '0.8em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Main Threat Factors</h5>
                      <CreatableSelect
                        isMulti
                        placeholder='Select Threat Factors'
                        name='colors'
                        className='select-container'
                        id='threat-factors'
                      // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        options={incidentTypes.map((type) => ({ label: type, value: type }))}
                        value={factors}
                        onChange={(selected) => setFactors(selected)}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '0.8em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Comment</h5>
                      <textarea
                        style={{ height: '5em', width: '100%' }}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder='Enter comment'
                      />
                    </IonCol>
                  </IonRow>
                </div>
                <IonRow className='ion-justify-content-end'>
                  <IonButton onClick={handleEdit} style={{ '--background': '#326771' }}>Save</IonButton>
                  <IonButton onClick={() => setEditing(false)} style={{ '--background': '#326771' }}>Cancel</IonButton>
                </IonRow>
              </>
            ) : (
              // Render view mode
              <>
                <IonRow className='ion-justify-content-between ion-align-items-center'>
                  <h5 style={{ marginLeft: '2em', fontSize: 16, color: '#326771' }}>{ name }</h5>
                </IonRow>
                <div style={{ height: '70%', overflow: 'auto' }}>
                  <IonRow style={{ marginLeft: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Review Date</h5>
                      <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{ moment(date).format('DD/MM/YYYY') }</p>
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Owner</h5>
                      <p style={{ margin: 0, fontSize: 13,color: '#737475' }}>{ owner.label }</p>
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '0.8em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Asset Alert State</h5>
                      <p style={{ margin: 0, fontSize: 13 }}>
                        <span style={{ ...getAlertStyle(selectedAsset.alert_state), padding: '4px 8px', borderRadius: '6px', }}>
                          {getAlertLabel(alert?.value)}
                        </span>
                      </p>
                      <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{getAlertDefinition(alert?.value)}</p>
                    </IonCol>
                    <IonCol>
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '0.8em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Main Threat Factors</h5>
                      <ul style={{ margin: 0, padding: 0, listStyleType: 'disc', paddingLeft: '20px' }}>
                        {factors.map((factor, index) => (
                          <li key={index} style={{ fontSize: 13, color: '#737475' }}>
                            {factor.value}
                          </li>
                        ))}
                      </ul>
                    </IonCol>
                    <IonCol />
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '0.8em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Comment</h5>
                      <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{comment}</p>
                    </IonCol>
                    <IonCol>
                    </IonCol>
                  </IonRow>
                </div>
                <IonRow className='ion-justify-content-end'>
                  <IonButton onClick={() => setEditing(true)} style={{ '--background': '#326771' }}>Edit</IonButton>
                </IonRow>
              </>
            )}
          </OverlayContainer>
        </>
      )
    case 'Notes':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Home')}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#8E151F', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Notes')}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Threat')}
                >Threat
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Flashpoints')}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Control Measures')}
                >Control Measures
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Risk Register')}
                >Risk Register
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => { setShowSelectedAsset(false); setEditing(false); }}>Close X</SimpleButton>
            </IonRow>
            <>
              <div style={{ height: '75%', overflow: 'auto', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <IonRow style={{ padding: '10px' }} className='terrain-list-title ion-justify-content-between ion-align-items-center'>
                  <IonCol size='1'>
                    <h5 style={{ fontSize: 16, color: '#326771' }}>Date</h5>
                  </IonCol>
                  <IonCol size='1.5'>
                    <h5 style={{ fontSize: 16, color: '#326771' }}>Submitted</h5>
                  </IonCol>
                  <IonCol size='2'>
                    <h5 style={{ fontSize: 16, color: '#326771' }}>Subject</h5>
                  </IonCol>
                  <IonCol size='7'>
                    <h5 style={{ fontSize: 16, color: '#326771' }}>Content</h5>
                  </IonCol>
                  <IonCol />
                </IonRow>
                <div style={{ flex: 1, overflow: 'auto' }}>
                  {selectedAsset?.notes?.length === 0 ? (
                    <p style={{ textAlign: 'center' }}>No Notes available</p>
                  ) : (
                    selectedAsset.notes.map((note, index) => (
                      editing ? (
                        <IonRow
                          className='ion-justify-content-between terrain-register-row ion-align-items-center'
                          style={{ backgroundColor: getColor(index) }}
                          key={note.id}
                        >
                          <IonCol size='1' style={{ fontSize: '13px', color: '#737475' }}>{moment(note.updated_at).format('DD/MM/YY')}</IonCol>
                          <IonCol size='1.5' style={{ fontSize: '13px', color: '#737475' }}>{getNoteUser(note.user_id).label}</IonCol>
                          <IonCol size='2' style={{ fontSize: '13px', color: '#737475' }}>{note.subject}</IonCol>
                          <IonCol size='7' style={{ fontSize: '13px', color: '#737475' }}>{note.content}</IonCol>
                          <IonCol size='0.5'>
                            <BlueButton onClick={() => setEditing(false)}>Edit</BlueButton>
                          </IonCol>
                        </IonRow>
                      ) : (
                        <IonRow
                          className='ion-justify-content-between terrain-register-row ion-align-items-center'
                          style={{ backgroundColor: getColor(index) }}
                          key={note.id}
                        >
                          <IonCol size='1' style={{ fontSize: '13px', color: '#737475' }}>{moment(note.updated_at).format('DD/MM/YY')}</IonCol>
                          <IonCol size='1.5' style={{ fontSize: '13px', color: '#737475' }}>{getNoteUser(note.user_id).label}</IonCol>
                          <IonCol size='2' style={{ fontSize: '13px', color: '#737475' }}>{note.subject}</IonCol>
                          <IonCol size='7' style={{ fontSize: '13px', color: '#737475' }}>{note.content}</IonCol>
                          <IonCol size='0.5'>
                            <BlueButton onClick={() => { setView('View Note'); setSelectedNote(note); setSubject(note.subject); setContent(note.content) }}>View</BlueButton>
                          </IonCol>
                        </IonRow>
                      )
                    ))
                  )}
                </div>
              </div>
              <IonRow className='ion-justify-content-end'>
                <IonButton onClick={() => setView('Add Note')} style={{ '--background': '#0C9500', marginTop: '1.4em' }}>Add Note</IonButton>
              </IonRow>
            </>
          </OverlayContainer>
        </>
      )
    case 'View Note':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {setView('Home'); setEditing(false) }}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#8E151F', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {setView('Notes'); setEditing(false) }}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {setView('Threat'); setEditing(false) }}
                >Threat
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {setView('Flashpoints'); setEditing(false) }}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {setView('Control Measures'); setEditing(false) }}
                >Control Measures
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {setView('Risk Register'); setEditing(false) }}
                >Risk Register
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => setShowSelectedAsset(false)}>Close X</SimpleButton>
            </IonRow>
            <>
              <div style={{ height: '79%', overflow: 'auto' }}>
                <IonRow style={{ marginLeft: '2em' }}>
                  <IonCol>
                    <h5 style={{ color: '#4197A9', fontSize: 13, marginBottom: '4px' }}>Date and Submitted By</h5>
                    <p style={{ fontSize: 13, color: '#737475' }}>
                      {new Date(selectedNote.created_at).toLocaleDateString('en-GB')} by {getNoteUser(selectedNote.user_id).label}
                    </p>
                    <h5 style={{ color: '#4197A9', fontSize: 13, marginBottom: '4px' }}>Subject</h5>
                    {editing ? (
                      <input
                        type='text'
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        style={{ width: '100%', fontSize: 13, color: '#737475', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                      />
                    ) : (
                      <p style={{ fontSize: 13, color: '#737475' }}>{selectedNote.subject}</p>
                    )}
                  </IonCol>
                </IonRow>
                <IonRow style={{ marginLeft: '2em', height: '59%' }}>
                  <IonCol size='12'>
                    <h5 style={{ color: '#4197A9', fontSize: 13, marginBottom: '4px' }}>Content</h5>
                    {editing ? (
                      <textarea
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        style={{ width: '100%', height: '100%', fontSize: 13, color: '#737475', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                      />
                    ) : (
                      <div style={{ width: '100%', height: '100%', fontSize: 13, color: '#737475', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', overflowY: 'auto', backgroundColor: '#f9f9f9' }}>
                        {selectedNote.content}
                      </div>
                    )}
                  </IonCol>
                </IonRow>
              </div>
              { editing ? (
                <IonRow className='ion-justify-content-end'>
                  <IonButton onClick={() => setEditing(false)} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
                  <IonButton onClick={handleEditNote} style={{ '--background': '#326771' }}>Save</IonButton>
                </IonRow>
              ) : (
                <IonRow className='ion-justify-content-end'>
                  <IonButton onClick={() => setView('Notes')} style={{ '--background': '#8E151F' }}>Back</IonButton>
                  <IonButton onClick={() => setEditing(true)} style={{ '--background': '#326771' }}>Edit</IonButton>
                </IonRow>
              )}
            </>
          </OverlayContainer>
        </>
      )
    case 'Add Note':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
              <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Home')}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#8E151F', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Notes')}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Threat')}
                >Threat
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Flashpoints')}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Control Measures')}
                >Control Measures
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Risk Register')}
                >Risk Register
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => setShowSelectedAsset(false)}>Close X</SimpleButton>
            </IonRow>
            <>
              <div style={{ height: '79%', overflow: 'auto' }}>
                <IonRow className='ion-justify-content-between ion-align-items-center'>
                  <h5 style={{ marginLeft: '2em', fontSize: 16, color: '#326771' }}>{ `${selectedAsset.name} - Add Note` }</h5>
                </IonRow>
                <IonRow style={{ marginLeft: '2em', height: '20%' }}>
                  <IonCol size='12'>
                    <h5 style={{ color: '#4197A9', fontSize: 13, marginBottom: '4px' }}>Subject</h5>
                    <input
                      type='text'
                      value={subject}
                      onChange={handleSubjectChange}
                      style={{ width: '100%', fontSize: 13, color: '#737475', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                      placeholder='Enter Subject'
                    />
                  </IonCol>
                </IonRow>
                <IonRow style={{ marginLeft: '2em', marginTop: '2em', height: '59%' }}>
                  <IonCol size='12'>
                    <h5 style={{ color: '#4197A9', fontSize: 13, marginBottom: '4px' }}>Content</h5>
                    <textarea
                      value={content}
                      onChange={handleContentChange}
                      style={{ width: '100%', height: '59%', fontSize: 13, color: '#737475', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                      placeholder='Enter Content'
                    />
                  </IonCol>
                </IonRow>
              </div>
              <IonRow className='ion-justify-content-end'>
                <IonButton onClick={() => setView('Notes')} style={{ '--background': '#8E151F' }}>Back</IonButton>
                <IonButton onClick={handleAddNote} style={{ '--background': '#326771' }}>Submit</IonButton>
              </IonRow>
            </>
          </OverlayContainer>
        </>
      )
    case 'Control Measures':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Home')
                  }}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Notes')
                  }}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Threat')
                  }}
                >Threat
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Flashpoints')
                  }}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#8E151F', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Control Measures')
                  }}
                >Control Measures
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Risk Register')
                  }}
                >Risk Register
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => setShowSelectedAsset(false)}>Close X</SimpleButton>
            </IonRow>
            <>
              <div style={{ height: '79%', overflow: 'auto' }}>
                {rows.map((row, rowIndex) => (
                  <IonRow key={rowIndex} style={{ marginLeft: '2em', marginTop: rowIndex === 0 ? '0' : '2em', height: '40%' }}>
                    {row.map((category) => (
                      <IonCol key={category}>
                        <h5 style={{ color: '#4197A9', fontSize: 13, marginBottom: '4px' }}>{category}</h5>
                        {groupedMeasures[category].map((measure) => (
                          <div key={measure.id} style={{ marginBottom: '10px' }}>
                            <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>
                              {measure.control_measure}
                            </p>
                          </div>
                        ))}
                      </IonCol>
                    ))}
                    {row.length < 3 && [...Array(3 - row.length)].map((_, index) => (
                      <IonCol key={`empty-${index}`} />
                    ))}
                  </IonRow>
                ))}
              </div>
              <IonRow className='ion-justify-content-end'>
                <IonButton onClick={() => { setView('Add Measures') }} style={{ '--background': '#326771' }}>Add Measure</IonButton>
              </IonRow>
            </>
          </OverlayContainer>
        </>
      )
    case 'Add Measures':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Home')}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Notes')}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Threat')}
                >Threat
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Flashpoints')}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#8E151F', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Control Measures')}
                >Control Measures
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Risk Register')}
                >Risk Register
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => setShowSelectedAsset(false)}>Close X</SimpleButton>
            </IonRow>
            <CenterForm style={{ zIndex: '20', width: '90%', height: '105%' }}>
              <IonContent className='risk-assessment-list' style={{ position: 'relative' }}>
                <IonList className='risk-assessment-list' style={{ padding: '20px' }}>
                  <div>
                    <SettingField>
                      <h5 className='blue'>Add Control Measures</h5>
                      <p>Control Measures are measures that are currently in place for this Key Asset. Use the drop-down to select a category,
                        and then <span style={{ color: '#326771' }}>drag and drop</span> items from the left table to the right.
                      </p>
                    </SettingField>
                    <div style={{ width: '200px' }}>
                      <Select
                        placeholder='Select Category'
                        name='existingCategory'
                        id='existing-control-measure-categories'
                        className='select-container'
                        value={input?.existingCategory}
                        // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        options={categories.map((level) => ({ value: level, label: level }))}
                        onChange={(selected) => setInput({ ...input, existingCategory: selected, edit: true })}
                      />
                    </div>
                    <IonRow className='ion-align-items-center'>
                      <IonCol size={5}>
                        <ColumnContainer onDragOver={(e) => e.preventDefault()} onDrop={handleDropRemove}>
                          {input?.existingCategory
                            && rawMeasures.filter(({ category }) => category === input?.existingCategory.value)
                              .filter((val) => !input?.existingMeasures.find((measure) => measure.id === val.id)).map((measure, index) => (
                                <MeasureContainer
                                  className='single-control-measure'
                                  key={index}
                                  draggable
                                  style={{ backgroundColor: getColor(index) }}
                                  onDragStart={(e) => e.dataTransfer.setData('measure', JSON.stringify(measure))}
                                >
                                  <p> {measure.control_measure} </p>
                                </MeasureContainer>
                              ))}
                          {!input?.existingCategory && (
                            <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
                              <SettingField style={{ textAlign: 'center', width: 'auto' }}>
                                <h4 style={{ fontWeight: 'normal', margin: '20px 0' }}>Choose a category to select measures</h4>
                                <p>To start adding control measures, choose a category from the drop-down menu above. </p>
                              </SettingField>
                            </IonRow>
                          )}
                        </ColumnContainer>
                      </IonCol>
                      <IonCol style={{ textAlign: 'center', fontSize: '2.6rem' }} size={2}>
                        <Icon icon='ic:round-swipe-right' />
                      </IonCol>
                      <IonCol size={5}>
                        <ColumnContainer id='cm-existing-drop' onDragOver={(e) => e.preventDefault()} onDrop={handleDropAdd}>
                          {input?.existingCategory && input?.existingMeasures.filter((selected) => selected.category === input?.existingCategory.value).map((measure, index) => (
                            <MeasureContainer
                              className='selected-control-measure'
                              key={index}
                              draggable
                              style={{ backgroundColor: getColor(index) }}
                              onDragStart={(e) => e.dataTransfer.setData('measure', JSON.stringify(measure))}
                            >
                              <p> {measure.control_measure} </p>
                            </MeasureContainer>
                          ))}
                          {(!input?.existingCategory || input?.existingMeasures.filter((selected) => selected.category === input?.existingCategory.value).length === 0) && (
                            <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
                              <SettingField style={{ textAlign: 'center', width: 'auto' }}>
                                <h4 style={{ fontWeight: 'normal', margin: '20px 0' }}>Drag and drop items from the left to add them to your Key Asset’s profile</h4>
                                <p>Control measures that you add here will appear in the Key Assets profile as existing measures.</p>
                              </SettingField>
                            </IonRow>
                          )}
                        </ColumnContainer>
                      </IonCol>
                    </IonRow>
                  </div>
                  <IonRow className='ion-justify-content-end'>
                    <IonButton style={{ '--background': '#4197A9' }} onClick={() => setView('Control Measures')}>Cancel</IonButton>
                    <IonButton style={{ '--background': '#326771' }} onClick={handleAddMeasures}>Submit</IonButton>
                  </IonRow>
                </IonList>
              </IonContent>
            </CenterForm>
          </OverlayContainer>
        </>
      )
    case 'Risk Register':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Home')}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Notes')}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Threat')}
                >Threat
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Flashpoints')}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Control Measures')}
                >Control Measures
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#8E151F', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Risk Register')}
                >Risk Register
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => { setShowSelectedAsset(false); setEditing(false) } }>Close X</SimpleButton>
            </IonRow>
            <IonRow className='ion-justify-content-between ion-align-items-center'>
              <h5 style={{ marginLeft: '2em', color: '#28464B', fontSize: 16 }}>{selectedAsset.name}</h5>
            </IonRow>
            <div style={{ flex: 1, overflow: 'auto' }}>
              {selectedAsset.flashpoints.map((val, index) => (
                <IonRow
                  className='ion-justify-content-between terrain-register-row ion-align-items-center'
                  style={{ backgroundColor: getColor(index) }}
                  key={val.id}
                >
                  <IonCol size='2' style={{ fontSize: '13px', color: '#737475' }}>{val.title}</IonCol>
                  <IonCol size='2' style={{ fontSize: '13px', color: '#737475' }}>{val.status}</IonCol>
                  <IonCol size='2' style={{ fontSize: '13px', color: '#737475' }}>{val.primary_manifestation}</IonCol>
                  <IonCol size='2' style={{ fontSize: '13px', color: '#737475' }}>{val.areas.map((area) => area.name).join('; ')}</IonCol>
                  <IonCol size='3' style={{ fontSize: '13px', color: '#737475' }}>{moment(val.updated_at).format('DD/MM/YYYY')}</IonCol>
                  <IonCol size='1'>
                    <BlueButton onClick={() => setEditing(false)}>View</BlueButton>
                  </IonCol>
                </IonRow>
              ))}
              {selectedAsset.flashpoints.length === 0 && (
                <p style={{ textAlign: 'center' }}>No Flashpoints available</p>
              )}
            </div>
          </OverlayContainer>
        </>
      )
    case 'Flashpoints':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Home')}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Notes')}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Threat')}
                >Threat
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#8E151F', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Flashpoints')}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Control Measures')}
                >Control Measures
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => setView('Risk Register')}
                >Risk Register
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => { setShowSelectedAsset(false); setEditing(false) } }>Close X</SimpleButton>
            </IonRow>
            <div style={{ height: '75%', overflow: 'auto', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <IonRow style={{ padding: '10px' }} className='terrain-list-title  ion-justify-content-between ion-align-items-center'>
                <IonCol size='2'>
                  <h5 style={{ fontSize: 16, color: '#326771' }}>Flashpoint</h5>
                </IonCol>
                <IonCol size='2'>
                  <h5 style={{ fontSize: 16, color: '#326771' }}>Status</h5>
                </IonCol>
                <IonCol size='2'>
                  <h5 style={{ fontSize: 16, color: '#326771' }}>Manifestation</h5>
                </IonCol>
                <IonCol size='2'>
                  <h5 style={{ fontSize: 16, color: '#326771' }}>Communities</h5>
                </IonCol>
                <IonCol size='3'>
                  <h5 style={{ fontSize: 16, color: '#326771' }}>Date Modified</h5>
                </IonCol>
                <IonCol size='1' />
              </IonRow>
              <div style={{ flex: 1, overflow: 'auto' }}>
                {selectedAsset.flashpoints.map((val, index) => (
                  <IonRow
                    className='ion-justify-content-between terrain-register-row ion-align-items-center'
                    style={{ backgroundColor: getColor(index) }}
                    key={val.id}
                  >
                    <IonCol size='2' style={{ fontSize: '13px', color: '#737475' }}>{val.title}</IonCol>
                    <IonCol size='2' style={{ fontSize: '13px', color: '#737475' }}>{val.status}</IonCol>
                    <IonCol size='2' style={{ fontSize: '13px', color: '#737475' }}>{val.primary_manifestation}</IonCol>
                    <IonCol size='2' style={{ fontSize: '13px', color: '#737475' }}>{val.areas.map((area) => area.name).join('; ')}</IonCol>
                    <IonCol size='3' style={{ fontSize: '13px', color: '#737475' }}>{moment(val.updated_at).format('DD/MM/YYYY')}</IonCol>
                    <IonCol size='1'>
                      <BlueButton onClick={() => {setSelectedFlashpoint(val); setView('View Flashpoint')}}>View</BlueButton>
                    </IonCol>
                  </IonRow>
                ))}
                {selectedAsset.flashpoints.length === 0 && (
                  <p style={{ textAlign: 'center' }}>No Flashpoints available</p>
                )}
              </div>
            </div>
            <IonRow className='ion-justify-content-end'>
              <IonButton onClick={() => setView('Add Flashpoint')} style={{ '--background': '#0C9500', marginTop: '1.4em' }}>Add Flashpoint</IonButton>
            </IonRow>
          </OverlayContainer>
        </>
      )
    case 'View Flashpoint':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Home')
                  }}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Notes')
                  }}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Threat')
                  }}
                >Threat
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#8E151F', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Flashpoints')
                  }}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Control Measures')
                  }}
                >Control Measures
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Risk Register')
                  }}
                >Risk Register
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => { setShowSelectedAsset(false); setEditing(false) }}>Close X</SimpleButton>
            </IonRow>
            {editing ? (
              <>
                <IonRow className='ion-justify-content-between ion-align-items-center'>
                  <h5 style={{ marginLeft: '2em', fontSize: 16, color: '#326771' }}>{ selectedFlashpoint.title }</h5>
                </IonRow>
                <div style={{ height: '70%', overflow: 'auto' }}>
                  <IonRow style={{ marginLeft: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Description</h5>
                      <textarea
                        style={{ height: '5em' }}
                        value={flashpointInput.description}
                        onChange={(e) => setFlashpointInput({ ...flashpointInput, description: e.target.value })}
                      />
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Triggers</h5>
                      <textarea
                        style={{ height: '5em' }}
                        value={flashpointInput.triggers}
                        onChange={(e) => setFlashpointInput({ ...flashpointInput, triggers: e.target.value })}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Status</h5>
                      <select
                        value={flashpointInput.status}
                        onChange={(e) => setFlashpointInput({ ...flashpointInput, status: e.target.value as FlashpointStatus })}
                        style={{ padding: '5px 10px' }}
                      >
                        <option value='' disabled>Select Status</option>
                        <option value='low'>Low</option>
                        <option value='guarded'>Guarded</option>
                        <option value='elevated'>Elevated</option>
                        <option value='high'>High</option>
                        <option value='severe'>Severe</option>
                      </select>
                      <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{getStatusDefinition(flashpointInput?.status)}</p>
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Is this ongoing/seasonal?</h5>
                      <IonRow>
                        <label style={{ textAlign: 'center', marginRight: '10px' }}>
                          <input
                            type='radio'
                            value='yes'
                            checked={input.ongoing === 'Yes'}
                            onChange={() => setInput({ ...input, ongoing: 'Yes' })}
                          />
                          Yes
                        </label>
                        <label style={{ textAlign: 'center' }}>
                          <input
                            type='radio'
                            value='No'
                            checked={input.ongoing === 'No'}
                            onChange={() => setInput({ ...input, ongoing: 'No' })}
                          />
                          No
                        </label>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Primary Manifestation</h5>
                      <select
                        value={flashpointInput.primaryManifestation}
                        onChange={(e) => setFlashpointInput({ ...flashpointInput, primaryManifestation: e.target.value as FlashpointStatus })}
                        style={{ padding: '5px 10px' }}
                      >
                        <option value='' disabled>Select manifestation</option>
                        {
                          manifestations?.map((val) => (
                            <option key={val} value={val}>{ val }</option>
                          ))
                        }
                      </select>
                    </IonCol>
                    <IonCol>
                      {
                        flashpointInput?.additionalManifestations?.map((val, index) => (
                          <div key={index} className='terrain-form-field'>
                            <h6 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Additional Manifestation (optional)</h6>
                            <select
                              value={val}
                              onChange={(e) => updateAdditionalManifest(e.target.value, index)}
                              style={{ padding: '5px 10px' }}
                            >
                              <option value='' disabled>Select manifestation</option>
                              {
                                manifestations?.map((manifestation) => (
                                  <option key={manifestation} value={manifestation}>{ manifestation }</option>
                                ))
                              }
                            </select>
                          </div>
                        ))
                      }
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Proximate Issues</h5>
                      <>
                        {
                          issues.map((val, index) => (
                            <TagButton
                              key={val.id}
                              onClick={() => selectCause(index)}
                              style={{ backgroundColor: (val.selected) ? '#326771' : 'white', color: (val.selected) ? 'white' : '#326771' }}
                            >
                              { val.name }
                            </TagButton>
                          ))
                        }
                      </>
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Primary Associated Stakeholders</h5>
                      <CreatableSelect
                        isMulti
                        placeholder='Select Stakeholders'
                        name='colors'
                        className='select-container'
                        id='journey-passengers'
                      // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        options={stakeholders.map(({ id, name }) => ({ value: id, label: name }))}
                        value={flashpointInput.primaryStakeholders}
                        onChange={(selected) => setFlashpointInput({ ...flashpointInput, primaryStakeholders: selected })}
                      />
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Secondary Associated Stakeholders</h5>
                      <CreatableSelect
                        isMulti
                        placeholder='Select Stakeholders'
                        name='colors'
                        className='select-container'
                        id='journey-passengers'
                      // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        options={stakeholders.map(({ id, name }) => ({ value: id, label: name }))}
                        value={flashpointInput.secondaryStakeholders}
                        onChange={(selected) => setFlashpointInput({ ...flashpointInput, secondaryStakeholders: selected })}
                      />
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Tertiary Associated Stakeholders</h5>
                      <CreatableSelect
                        isMulti
                        placeholder='Select Stakeholders'
                        name='colors'
                        className='select-container'
                        id='journey-passengers'
                      // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        options={stakeholders.map(({ id, name }) => ({ value: id, label: name }))}
                        value={flashpointInput.tertiaryStakeholders}
                        onChange={(selected) => setFlashpointInput({ ...flashpointInput, tertiaryStakeholders: selected })}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Relevant Areas</h5>
                      <CreatableSelect
                        isMulti
                        placeholder='Select Areas'
                        name='colors'
                        className='select-container'
                        id='journey-passengers'
                      // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        options={communities.map(({ id, name }) => ({ label: name, value: id }))}
                        value={flashpointInput.relevantAreas}
                        onChange={(selected) => setFlashpointInput({ ...flashpointInput, relevantAreas: selected })}
                      />
                    </IonCol>
                    <IonCol />
                    <IonCol />
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Additional Comments</h5>
                      <textarea
                        style={{ width: '100%', height: '59%', fontSize: 13, color: '#737475', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                        value={flashpointInput.comments}
                        onChange={(e) => setFlashpointInput({ ...flashpointInput, comments: e.target.value })}
                        placeholder='Enter comments'
                      />
                    </IonCol>
                  </IonRow>
                </div>
                <IonRow className='ion-justify-content-end'>
                  <IonButton onClick={handleEditFlashpoint} style={{ '--background': '#326771' }}>Save</IonButton>
                  <IonButton onClick={() => setEditing(false)} style={{ '--background': '#326771' }}>Cancel</IonButton>
                </IonRow>
              </>
            ) : (
              <>
                <IonRow className='ion-justify-content-between ion-align-items-center'>
                  <h5 style={{ marginLeft: '2em', fontSize: 16, color: '#326771' }}>{ selectedFlashpoint.title }</h5>
                </IonRow>
                <div style={{ height: '70%', overflow: 'auto' }}>
                  <IonRow style={{ marginLeft: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Description</h5>
                      <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{ selectedFlashpoint.description }</p>
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Triggers</h5>
                      <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{ selectedFlashpoint.triggers }</p>
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Status</h5>
                      <p style={{ margin: 0, fontSize: 13 }}>
                        <span style={{ ...getStatusStyle(selectedFlashpoint.status), padding: '4px 8px', borderRadius: '6px' }}>
                          {getStatusLabel(selectedFlashpoint?.status)}
                        </span>
                      </p>
                      <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{getStatusDefinition(selectedFlashpoint?.status)}</p>
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Is this ongoing/seasonal?</h5>
                      <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{ (selectedFlashpoint.ongoing) ? 'Yes' : 'No' }</p>
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Primary Manifestation</h5>
                      <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{ selectedFlashpoint.primary_manifestation }</p>
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Additional Manifestations</h5>
                      <>
                        {
                          selectedFlashpoint?.additional_manifestations?.length === 0 ? (
                            <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>No additional manifestations</p>
                          ) : (
                            <ul>
                              {
                                selectedFlashpoint?.additional_manifestations?.map((val) => (
                                  <li key={val.id}><p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{ val.name }</p></li>
                                ))
                              }
                            </ul>
                          )
                        }
                      </>
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Proximate Issues</h5>
                      <>
                        {
                          selectedFlashpoint.issues.map((val) => (
                            <IssueTag key={val.id}>{ val.name }</IssueTag>
                          ))
                        }
                      </>
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Primary Associated Stakeholders</h5>
                      {selectedFlashpoint.flashpoint_groups
                        .filter((item) => item.group_type === 'primary') // Inline filtering
                        .map((stakeholder) => (
                          <div key={stakeholder.id}>
                            <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>
                              {stakeholder.stakeholder.name}
                            </p>
                          </div>
                        ))
                      }
                      {selectedFlashpoint.flashpoint_groups.filter((item) => item.group_type === 'primary').length === 0 && (
                        <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>No primary stakeholders available</p>
                      )}
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Secondary Associated Stakeholders</h5>
                      {selectedFlashpoint.flashpoint_groups
                        .filter((item) => item.group_type === 'secondary') // Inline filtering
                        .map((stakeholder) => (
                          <div key={stakeholder.id}>
                            <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>
                              {stakeholder.stakeholder.name}
                            </p>
                          </div>
                        ))
                      }
                      {selectedFlashpoint.flashpoint_groups.filter((item) => item.group_type === 'secondary').length === 0 && (
                        <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>No secondary stakeholders available</p>
                      )}
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Tertiary Associated Stakeholders</h5>
                      {selectedFlashpoint.flashpoint_groups
                        .filter((item) => item.group_type === 'tertiary') // Inline filtering
                        .map((stakeholder) => (
                          <div key={stakeholder.id}>
                            <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>
                              {stakeholder.stakeholder.name}
                            </p>
                          </div>
                        ))
                      }
                      {selectedFlashpoint.flashpoint_groups.filter((item) => item.group_type === 'tertiary').length === 0 && (
                        <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>No tertiary stakeholders available</p>
                      )}
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Relevant Areas</h5>
                      {selectedFlashpoint.areas
                        .map((area) => (
                          <div key={area.id}>
                            <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>
                              {area.name}
                            </p>
                          </div>
                        ))
                      }
                      {selectedFlashpoint.areas.length === 0 && (
                        <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>No areas available</p>
                      )}
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Additional Comments</h5>
                      <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{ selectedFlashpoint.comments }</p>
                    </IonCol>
                  </IonRow>
                </div>
                <IonRow className='ion-justify-content-end'>
                  <IonButton onClick={() => setView('Flashpoints')} style={{ '--background': '#8E151F' }}>Back</IonButton>
                  <IonButton onClick={() => setEditing(true)} style={{ '--background': '#326771' }}>Edit</IonButton>
                </IonRow>
              </>
            )}
          </OverlayContainer>
        </>
      )
    case 'Add Flashpoint':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Home')
                  }}
                >Overview
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Notes')
                  }}
                >Notes
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Threat')
                  }}
                >Threat
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#8E151F', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Flashpoints')
                  }}
                >Flashpoints
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Control Measures')
                  }}
                >Control Measures
                </TabOption>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#28464B', fontWeight: 'bold', fontSize: '16',
                  }}
                  onClick={() => {
                    setView('Risk Register')
                  }}
                >Risk Register
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => { setShowSelectedAsset(false); setEditing(false) }}>Close X</SimpleButton>
            </IonRow>
            <IonRow className='ion-justify-content-between ion-align-items-center'>
              <h5 style={{ marginLeft: '2em', fontSize: 16, color: '#326771' }}>Add Flashpoint</h5>
            </IonRow>
            <div style={{ height: '70%', overflow: 'auto' }}>
              <IonRow style={{ marginLeft: '2em' }}>
                <IonCol>
                  <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Title</h5>
                  <input placeholder='Give this flashpoint a title' type='text' value={flashpointInput.title} onChange={(e) => setFlashpointInput({ ...flashpointInput, title: e.target.value })} />
                </IonCol>
              </IonRow>
              <IonRow style={{ marginLeft: '2em' }}>
                <IonCol>
                  <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Description</h5>
                  <textarea
                    style={{ height: '5em' }}
                    value={flashpointInput.description}
                    onChange={(e) => setFlashpointInput({ ...flashpointInput, description: e.target.value })}
                    placeholder='Enter Description'
                  />
                </IonCol>
                <IonCol>
                  <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Triggers</h5>
                  <textarea
                    style={{ height: '5em' }}
                    value={flashpointInput.triggers}
                    onChange={(e) => setFlashpointInput({ ...flashpointInput, triggers: e.target.value })}
                    placeholder='Enter what triggers the flashpoint'
                  />
                </IonCol>
              </IonRow>
              <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                <IonCol>
                  <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Status</h5>
                  <select
                    value={flashpointInput.status}
                    onChange={(e) => setFlashpointInput({ ...flashpointInput, status: e.target.value as FlashpointStatus })}
                    style={{ padding: '5px 10px' }}
                  >
                    <option value='' disabled>Select Status</option>
                    <option value='low'>Low</option>
                    <option value='guarded'>Guarded</option>
                    <option value='elevated'>Elevated</option>
                    <option value='high'>High</option>
                    <option value='severe'>Severe</option>
                  </select>
                </IonCol>
                <IonCol>
                  <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Is this ongoing/seasonal?</h5>
                  <IonRow>
                    <label style={{ textAlign: 'center', marginRight: '10px' }}>
                      <input
                        type='radio'
                        value='yes'
                        checked={input.ongoing === 'Yes'}
                        onChange={() => setInput({ ...input, ongoing: 'Yes' })}
                      />
                      Yes
                    </label>
                    <label style={{ textAlign: 'center' }}>
                      <input
                        type='radio'
                        value='No'
                        checked={input.ongoing === 'No'}
                        onChange={() => setInput({ ...input, ongoing: 'No' })}
                      />
                      No
                    </label>
                  </IonRow>
                </IonCol>
              </IonRow>
              <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                <IonCol>
                  <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Primary Manifestation</h5>
                  <select
                    value={flashpointInput.primaryManifestation}
                    onChange={(e) => setFlashpointInput({ ...flashpointInput, primaryManifestation: e.target.value as FlashpointStatus })}
                    style={{ padding: '5px 10px' }}
                  >
                    <option value='' disabled>Select manifestation</option>
                    {
                      manifestations?.map((val) => (
                        <option key={val} value={val}>{ val }</option>
                      ))
                    }
                  </select>
                </IonCol>
                <IonCol>
                  {
                    flashpointInput.additionalManifestations.map((val, index) => (
                      <div key={index} className='terrain-form-field'>
                        <h6>Additional Manifestation (optional)</h6>
                        <select
                          value={val}
                          onChange={(e) => updateAdditionalManifest(e.target.value, index)}
                          style={{ padding: '5px 10px' }}
                        >
                          <option value='' disabled>Select manifestation</option>
                          {
                            manifestations?.map((manifestation) => (
                              <option key={manifestation} value={manifestation}>{ manifestation }</option>
                            ))
                          }
                        </select>
                      </div>
                    ))
                  }
                  <SimpleButton style={{ fontSize: '0.8rem' }} onClick={addManifestation}>Add Additional Manifestation +</SimpleButton>
                </IonCol>
              </IonRow>
              <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                <IonCol>
                  <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Proximate Issues</h5>
                  <>
                    {
                      issues.map((val, index) => (
                        <TagButton
                          key={val.id}
                          onClick={() => selectCause(index)}
                          style={{ backgroundColor: (val.selected) ? '#326771' : 'white', color: (val.selected) ? 'white' : '#326771' }}
                        >
                          { val.name }
                        </TagButton>
                      ))
                    }
                  </>
                </IonCol>
              </IonRow>
              <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                <IonCol>
                  <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Primary Associated Stakeholders</h5>
                  <CreatableSelect
                    isMulti
                    placeholder='Select Stakeholders'
                    name='colors'
                    className='select-container'
                    id='journey-passengers'
                  // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    options={stakeholders.map(({ id, name }) => ({ value: id, label: name }))}
                    value={flashpointInput.primaryStakeholders}
                    onChange={(selected) => setFlashpointInput({ ...flashpointInput, primaryStakeholders: selected })}
                  />
                </IonCol>
                <IonCol>
                  <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Secondary Associated Stakeholders</h5>
                  <CreatableSelect
                    isMulti
                    placeholder='Select Stakeholders'
                    name='colors'
                    className='select-container'
                    id='journey-passengers'
                  // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    options={stakeholders.map(({ id, name }) => ({ value: id, label: name }))}
                    value={flashpointInput.secondaryStakeholders}
                    onChange={(selected) => setFlashpointInput({ ...flashpointInput, secondaryStakeholders: selected })}
                  />
                </IonCol>
                <IonCol>
                  <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Tertiary Associated Stakeholders</h5>
                  <CreatableSelect
                    isMulti
                    placeholder='Select Stakeholders'
                    name='colors'
                    className='select-container'
                    id='journey-passengers'
                  // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    options={stakeholders.map(({ id, name }) => ({ value: id, label: name }))}
                    value={flashpointInput.tertiaryStakeholders}
                    onChange={(selected) => setFlashpointInput({ ...flashpointInput, tertiaryStakeholders: selected })}
                  />
                </IonCol>
              </IonRow>
              <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                <IonCol>
                  <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Relevant Areas</h5>
                  <CreatableSelect
                    isMulti
                    placeholder='Select Areas'
                    name='colors'
                    className='select-container'
                    id='journey-passengers'
                  // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    options={communities.map(({ id, name }) => ({ label: name, value: id }))}
                    value={flashpointInput.relevantAreas}
                    onChange={(selected) => setFlashpointInput({ ...flashpointInput, relevantAreas: selected })}
                  />
                </IonCol>
                <IonCol />
                <IonCol />
              </IonRow>
              <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                <IonCol>
                  <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Additional Comments</h5>
                  <textarea
                    style={{ width: '100%', height: '59%', fontSize: 13, color: '#737475', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                    value={flashpointInput.comments}
                    onChange={(e) => setFlashpointInput({ ...flashpointInput, comments: e.target.value })}
                    placeholder='Enter comments'
                  />
                </IonCol>
              </IonRow>
            </div>
            <IonRow className='ion-justify-content-end'>
              <IonButton onClick={handleAddFlashpoint} style={{ '--background': '#326771' }}>Save</IonButton>
              <IonButton onClick={() => setView('Flashpoints')} style={{ '--background': '#326771' }}>Cancel</IonButton>
            </IonRow>
          </OverlayContainer>
        </>
      )
  }
}

export default AssetProfileV2
