import { IonCol, IonRow } from '@ionic/react'
import React, { FC } from 'react'
import { FocusedActionKind } from '../../../hooks/terrain-mapping/helpers/StateReducers'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { ListContainer } from '../../consultant/StyledContainers'
import { BlueButton } from '../../incident-management/StyledContainers'
import FlashpointScreen from '../popovers/FlashpointScreen'

export const getColor = (index: number) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

const FlashpointList : FC = () => {
  const { focusedElems } = useTerrainMapping()

  if (focusedElems.focusedFlashpoint) {
    return (
      <FlashpointScreen />
    )
  }

  return (
    <List />
  )
}

const List = () => {
  const { flashpoints, dispatchFocused } = useTerrainMapping()

  return (
    <ListContainer style={{ backgroundColor: 'white', height: '100%' }}>
      <div style={{ height: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
        <IonRow style={{ padding: '10px' }} className='terrain-list-title ion-justify-content-between ion-align-items-center'>
          <IonCol size='2'>
            <h5>Title</h5>
          </IonCol>
          <IonCol size='4'>
            <h5>Description</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Status</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Manifestation</h5>
          </IonCol>
          <IonCol size='2' />
        </IonRow>
        <div style={{ flex: 1, overflow: 'auto' }}>
          {
            flashpoints.map((val, index) => (
              <IonRow
                className='ion-justify-content-between terrain-register-row ion-align-items-center'
                style={{ backgroundColor: getColor(index) }}
                key={val.id}
              >
                <IonCol size='2'>{ val.title }</IonCol>
                <IonCol size='4'>{ val.description }</IonCol>
                <IonCol size='2'>{ val.status }</IonCol>
                <IonCol size='2'>{ val.primaryManifestation }</IonCol>
                <IonCol size='2'>
                  <BlueButton onClick={() => dispatchFocused({ type: FocusedActionKind.SELECT_FLASHPOINT, flashpoint: val })}>View</BlueButton>
                </IonCol>
              </IonRow>
            ))
          }
          {
            flashpoints.length === 0 && (
              <p style={{ textAlign: 'center' }}>No flashpoints created so far</p>
            )
          }
        </div>
      </div>
    </ListContainer>
  )
}

export default FlashpointList
